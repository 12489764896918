@import 'src/styles/custom/variables/variables';
@import 'src/styles/vendor/bootstrap/bootstrap-variables';

.tile {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;

  &--one-quarter {
    height: 25%;
  }

  &--half-size {
    height: 50%;
  }

  &--three-quarters {
    height: 75%;
  }

  &--full-size {
    height: 100%;
  }

  &__content {
    position: relative;
    flex-grow: 1;
    height: 0;
    background-color: $color-white;
    box-shadow: $box-shadow-default;

    &--white {
      border: 3px solid $color-white;
    }

    &--grey {
      border: 3px solid $color-grey-1;
    }

    &--green {
      border: 3px solid $color-green-1;
    }

    &--red {
      border: 3px solid $color-red;
    }

    &--transparent {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &__data {
    height: 100%;
    overflow: hidden;
  }

  &__link {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &:hover::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 5px $color-grey-1;
      pointer-events: none;
    }

    &--has-pointer-events {
      &:hover::after {
        pointer-events: all;
      }
    }
  }
}
