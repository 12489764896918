@import 'src/styles/custom/variables/variables';

.nav__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: none;
  border-radius: 5px;
  background-color: $color-grey-2;

  @include no-font-scaling-parent {
    width: 35px;
    height: 35px;
  }

  &:hover {
    background-color: $color-grey-6;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background-color: $color-grey-3;
  }

  .icon {
    width: 25px;
    height: 41px;
    transform: rotate(-180deg);

    @include no-font-scaling-parent {
      width: 10px;
      height: 17px;
    }
  }

  &--right {
    right: 40px;
    left: auto;

    .icon {
      transform: rotate(0);
    }
  }
}
