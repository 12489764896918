@import 'src/styles/custom/variables/variables';
@import 'src/styles/custom/variables/colors';

// overriding styles
.bootstrap-tooltip {
  z-index: 1000;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;

  @media only screen and (min-width: 3000px) {
    font-size: 16px;
  }

  .bootstrap-tooltip {
    max-width: 172px;
    margin-top: 20px;
    padding: 15px;
    background-color: $color-orange-2;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
    word-break: break-word;
    white-space: pre-wrap;

    &--white-inner {
      background-color: $color-white;
    }
  }
}
