.measure-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

  &:focus {
    outline: none;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }


  &--small {
    .icon {
      width: 12px;
      height: 12px;
    }
  }

  &--medium {
    .icon {
      width: 15px;
      height: 15px;
    }
  }

  &--big {
    .icon {
      width: 80px;
      height: 80px;
    }
  }
}
