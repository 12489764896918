@import 'src/styles/custom/variables/colors';

.history-button.no-font-scaling {
  border: none;
  background-color: $color-red-9;

  &__desktop-screen {
    width: 35px;
    height: 35px;
    border-radius: 2px;

    &--left {
      margin-right: 30px;
    }

    &--right {
      margin-left: 30px;
    }

    &--back-to-board {
      margin-left: 7px;
    }
  }

  &__tv-screen {
    width: 85px;
    height: 85px;
    border-radius: 5px;

    &--left {
      margin-right: 100px;
    }

    &--right {
      margin-left: 100px;
    }

    &--back-to-board {
      margin-left: 20px;
    }
  }

}
