@import 'src/styles/custom/variables/variables';

.back-office-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include no-font-scaling-parent {
    .back-office-page__title {
      margin: 37px 0 16px;
      font-size: 22px;

      &:first-child {
        margin-top: 0;
      }
    }

    .back-office-page__content {
      width: 800px;
      padding: 50px;
    }

    td {
      padding: 14px 12px;
    }

    .back-office-page__toolbar {
      margin: 24px 0 0;
    }

    .input {
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__toolbar {
    display: flex;
    margin: 45px 0 10px;
  }

  &__content {
    flex-grow: 1;
    width: 1900px;
    margin-right: auto;
    margin-left: auto;
    padding: 120px 80px;
    background-color: $color-white;
  }

  &__title {
    margin: 76px 0 49px;
    font-size: 52px;
    font-weight: 600;
    line-height: 100%;

    &:first-child {
      margin-top: 0;
    }
  }

  &__table {
    width: auto;
  }

  &__toggle-switch-text {
    margin-left: 20px;
  }

  &__edit-link {
    color: $color-grey-1;

    &--disabled {
      opacity: 0.5;
      color: $color-grey-2;
      text-decoration: none;
      pointer-events: none;
    }
  }

  .input {
    margin-bottom: 90px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a:hover {
    color: $color-grey-1;
  }

  tr {
    font-size: 36px;
    font-weight: 600;
    line-height: 100%;
  }

  td {
    padding: 38px 36px;

    &:nth-child(2) {
      width: 200px;
      text-align: center;
    }
  }
}
