@import 'src/styles/vendor/bootstrap/bootstrap-variables';
@import 'src/styles/custom/variables/variables';

.board-grid {
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: $grid-gutter-width;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-template-rows: repeat(4, minmax(0, 1fr));
  flex: 1;
  height: 100%;
  padding: 0 $grid-gutter-width $grid-gutter-width;

  @include no-font-scaling-parent {
    padding: 0 $desktop-grid-gutter-width 15px;
  }

  &--brlboard {
    grid-template-areas:
      'a1 mt1 mt1 mt1 p1'
      'a2 mt1 mt1 mt1 p1'
      'a3 mt1 mt1 mt1 b1'
      'gh gh me1 me1 b2'
      'a4 a5 me1 me1 b2';
    grid-template-rows: repeat(3, minmax(0, 1fr)) auto minmax(0, 1fr);
  }

  &--brlboard-desktop {
    grid-template-areas:
      'a1  a1  mt1 mt1 mt1 mt1 mt1 mt1 p1  p1'
      'a1  a1  mt1 mt1 mt1 mt1 mt1 mt1 p1  p1'
      'a2  a2  mt1 mt1 mt1 mt1 mt1 mt1 dz1 dz1'
      'a2  a2  mt1 mt1 mt1 mt1 mt1 mt1 dz1 dz1'
      'a3  a3  mt1 mt1 mt1 mt1 mt1 mt1 me1 me1'
      'a3  a3  mt1 mt1 mt1 mt1 mt1 mt1 me1 me1'
      'gh  gh  mt1 mt1 mt1 mt1 mt1 mt1 me1 me1'
      'dv1 dv2 mt1 mt1 mt1 mt1 mt1 mt1 me1 me1'
      'dv1 dv2 mt1 mt1 mt1 mt1 mt1 mt1 me1 me1';
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, 1fr)) auto repeat(2, minmax(0, 1fr));
  }

  &--gfboard {
    grid-template-areas:
      'a1 md1 md1 md1 z1'
      'a2 md1 md1 md1 me1'
      'a3 md1 md1 md1 me1'
      'v1 md1 md1 md1 .';
  }

  &--gfboard-desktop {
    grid-template-areas:
      'a1 md1 md1 md1 z1'
      'a2 md1 md1 md1 me1'
      'a3 md1 md1 md1 me1'
      'v1 md1 md1 md1 .';

    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  &--plboard {
    &-1 {
      grid-template-areas:
        'u1 al1 ar1 p1 t1'
        'u2 al2 l1 p2 t2'
        '. al3 l2 q1 _e1'
        '. al4 l3 q2 _e1';
    }

    &-2 {

      grid-template-areas:
        'u1 al1 p1 p2 b1'
        'u2 al2 p3 p4 b2'
        'u3 al3 p5 p6 b3'
        'u4 al4 gh gh l1'
        'u4 al4 ar1 ar2 l1';
      grid-template-rows: repeat(3, minmax(0, 1fr)) auto minmax(0, 1fr);
    }

    &-3 {
      grid-template-areas:
        'b1 t1 aaz1 q1 f1'
        'b2 t2 aaz2 q2 a1'
        'b3 t3 aaz3 q3 _e1'
        'b4 t4 aaz4 .  _e1';
    }
  }

  &--plboard-desktop {
    &-1 {
      grid-template-areas:
        'u1 al1 ar1 p1 t1'
        'u2 al2 l1 p2 t2'
        '. al3 l2 q1 _e1'
        '. al4 l3 q2 _e1';
    }

    &-2 {
      grid-template-areas:
        'u1 al1 p1 p2 b1'
        'u2 al2 p3 p4 b2'
        'u3 al3 p5 p6 b3'
        'u4 al4 gh gh l1'
        'u4 al4 ar1 ar2 l1';
      grid-template-rows: repeat(3, minmax(0, 1fr)) auto minmax(0, 1fr);
    }

    &-3 {
      grid-template-areas:
        'b1 t1 aaz1 q1 f1'
        'b2 t2 aaz2 q2 a1'
        'b3 t3 aaz3 q3 _e1'
        'b4 t4 aaz4 .  _e1';
    }
  }

  .a1 {
    grid-area: a1;
  }

  .a2 {
    grid-area: a2;
  }

  .a3 {
    grid-area: a3;
  }

  .a4 {
    grid-area: a4;
  }

  .a5 {
    grid-area: a5;
  }

  .a6 {
    grid-area: a6;
  }

  .b1 {
    grid-area: b1;
  }

  .b2 {
    grid-area: b2;
  }

  .b3 {
    grid-area: b3;
  }

  .b4 {
    grid-area: b4;
  }

  .e1 {
    grid-area: _e1; // Bug: e1 compiles to NaN for some reason
  }

  .f1 {
    grid-area: f1;
  }

  .k1 {
    grid-area: k1;
  }

  .m1 {
    grid-area: m1;
  }

  .p1 {
    grid-area: p1;
  }

  .p2 {
    grid-area: p2;
  }

  .p3 {
    grid-area: p3;
  }

  .p4 {
    grid-area: p4;
  }

  .p5 {
    grid-area: p5;
  }

  .p6 {
    grid-area: p6;
  }

  .z1 {
    grid-area: z1;
  }

  .md1 {
    grid-area: md1;
  }

  .mt1 {
    grid-area: mt1;
  }

  .gh {
    grid-area: gh; // grid heading
    align-items: start;
  }

  .v1 {
    grid-area: v1;
  }

  .dv1 {
    grid-area: dv1;
  }

  .dv2 {
    grid-area: dv2;
  }

  .dz1 {
    grid-area: dz1;
  }

  .me1 {
    grid-area: me1;
  }

  .u1 {
    grid-area: u1;
  }

  .u2 {
    grid-area: u2;
  }

  .u3 {
    grid-area: u3;
  }

  .u4 {
    grid-area: u4;
  }

  .al1 {
    grid-area: al1;
  }

  .al2 {
    grid-area: al2;
  }

  .al3 {
    grid-area: al3;
  }

  .al4 {
    grid-area: al4;
  }

  .l1 {
    grid-area: l1;
  }

  .l2 {
    grid-area: l2;
  }

  .l3 {
    grid-area: l3;
  }

  .q1 {
    grid-area: q1;
  }

  .q2 {
    grid-area: q2;
  }

  .q3 {
    grid-area: q3;
  }

  .t1 {
    grid-area: t1;
  }

  .t2 {
    grid-area: t2;
  }

  .t3 {
    grid-area: t3;
  }

  .t4 {
    grid-area: t4;
  }

  .ar1 {
    grid-area: ar1;
  }

  .ar2 {
    grid-area: ar2;
  }

  .aaz1 {
    grid-area: aaz1;
  }

  .aaz2 {
    grid-area: aaz2;
  }

  .aaz3 {
    grid-area: aaz3;
  }

  .aaz4 {
    grid-area: aaz4;
  }
}

