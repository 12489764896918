html {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
  font-size: 36px;

  @media screen and (max-width: 3839px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1600px) {
    font-size: 13px;
  }

  @media screen and (max-width: 1500px) {
    font-size: 13px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 12px;
  }
}


body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 100vh;

  color: $color-grey-1;
  font-family: 'Source Sans Pro', sans-serif;
}

h1 {
  font-size: 50px;
  font-weight: 600;
  line-height: 63px;
}

p {
  font-size: 16px;
  line-height: 20px;
}
