@import 'src/styles/custom/variables/variables';

.table-textarea {
  position: relative;
  width: 100%;
  height: 100%;

  &__textarea {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    border: none;
    outline: none;
    background-color: $color-white;
    color: $color-grey-1;
    font-size: 16px;
    resize: none;
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__text {
    // stylelint-disable
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    // stylelint-enable

    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: calc(6 * 1rem);
    margin: 10px 10px 42px;
    overflow: hidden;
    white-space: pre-wrap;

    &--center {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 20px;
      text-align: center;
    }
  }

  &__status {
    display: inline-flex;
    position: absolute;
    z-index: 4;
    right: 0;
    bottom: 0;
    align-items: flex-end;
    justify-content: flex-end;
    width: 50px;
    height: 40px;
    padding: 0;
    border: none;
    background-color: transparent;
    color: transparent;
    font-size: 0;

    &:focus {
      outline: none;
    }
  }

  &__status-inner {
    position: relative;
    width: 30px;
    height: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    border: 1px solid $color-grey-2;
    border-radius: 2px;

    &::after {
      content: ' ';
      display: inline-block;
      position: absolute;
      z-index: 2;
      bottom: 1px;
      left: 0;
      width: 32px;
      height: 1px;
      transform: rotate(-20deg);
      transform-origin: left center;
      background-color: $color-grey-2;
    }

    &--green {
      background-color: $color-green-1;

      &::after {
        content: none;
      }
    }

    &--red {
      background-color: $color-red-4;

      &::after {
        content: none;
      }
    }
  }

  &__add {
    display: inline-flex;
    position: absolute;
    z-index: 5;
    right: 55px;
    bottom: 10px;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    padding: 0;
    border: 1px solid $color-grey-2;
    border-radius: 2px;
    background-color: $color-white;

    .icon {
      width: 10px;
      height: 10px;
    }
  }

  &__tooltip {
    position: absolute;
    z-index: 5;
    bottom: 10px;
    left: 10px;

    &-inner {
      display: inline-block;
      width: 31px;
      height: 13px;
      border: 1px solid $color-black;
      border-radius: 2px;
      background-color: $color-white;
      color: $color-black;
      font-size: 20px;
      line-height: 1px;
      text-align: center;
    }

    &--green,
    &--red {
      .table-textarea__tooltip-inner {
        border: 1px solid $color-white;
      }
    }
  }

  &__delete {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    padding: 10px;
    border: none;
    background-color: transparent;

    .icon {
      width: 14px;
      height: 18px;
    }
  }

  &--small &__input {
    width: calc(100% - 20px);
    margin: 0 10px;
  }

  &--edit &__text {
    visibility: hidden;
  }

  &--edit &__overlay,
  &--edit &__tooltip {
    display: none;
  }
}
