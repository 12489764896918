@import 'src/styles/custom/variables/variables';

.tile-occupational-safety-desktop-screen.no-font-scaling {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  .tile-occupational-safety__table {
    margin: 2px 9px 3px 5px;

    td {
      width: 20px;
      height: 20px;
    }
  }

  .tile-occupational-safety__td {
    padding: 2px;
    font-size: 9px;
  }

  .tile-occupational-safety__date {
    padding: 0;
  }

  .tile-occupational-safety-desktop-screen__table-header {
    padding: 5px 15px;
    border: none;
    border-bottom: 1px solid $color-grey-3;
    background: $color-grey-4;
    font-size: 14px;
    font-weight: 600;
  }

  .tile-occupational-safety-desktop-screen__table-wrap {
    padding: 2px 9px 3px 5px;
    border-right: 1px solid $color-grey-3;
  }

  .tile-occupational-safety-desktop-screen__content {
    display: flex;
    flex: 1;
  }

  .tile-occupational-safety-desktop-screen__right {
    padding: 3px 8px;
  }

  .tile-occupational-safety-desktop-screen__month {
    margin-bottom: 9px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
  }

  .tile-occupational-safety__table-info-item {
    margin-bottom: 4px;
    font-size: 12px;
  }

  .tile-occupational-safety__table-info-box {
    width: 15px;
    height: 15px;
    margin-right: 9px;
  }

  .tile-occupational-safety__table-info-number {
    margin: 0;
  }
}
