@import 'src/styles/custom/variables/colors';

.login-page.no-font-scaling {
  background-size: cover;

  &__desktop-screen {
    background-image: url('../../../../src/img/login-image-s.png');

    .login-page__content {
      width: 460px;
      height: 520px;
      opacity: 0.95;
      background: $color-white;
      box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
    }
    
    .login-page__logo {
      width: 150px;
      height: 150px;
      margin-top: 75px;
      margin-right: auto;
      margin-left: auto;
    }
    
    .login-page__title {
      margin-top: 30px;
      font-size: 32px;
      font-style: normal;
      font-weight: bold;
      line-height: 115.5%;
      text-align: center;
    }
    
    .login-page__subtitle {
      margin-top: 15px;
      margin-right: 41px;
      margin-left: 41px;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 115.5%;
      text-align: center;
    }
    
    .login-page__button {
      width: 120px;
      height: 35px;
      margin-top: 25px;
      border-radius: 2px;
      background: $color-red-7;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 115.5%;
    }
  }

  &__tv-screen {
    background-image: url('../../../../src/img/login-image-l.png');

    .login-page__content {
      width: 1150px;
      height: 1350px;
      opacity: 0.95;
      background: $color-white;
      box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
    }
    
    .login-page__logo {
      width: 350px;
      height: 350px;
      margin-top: 208px;
      margin-right: auto;
      margin-left: auto;
    }
    
    .login-page__title {
      margin-top: 72px;
      font-size: 120px;
      font-style: normal;
      font-weight: bold;
      line-height: 100%;
      text-align: center;
    }
    
    .login-page__subtitle {
      margin-top: 35px;
      margin-right: 115px;
      margin-left: 115px;
      font-size: 36px;
      font-style: normal;
      font-weight: normal;
      line-height: 113%;
      text-align: center;
    }
    
    .login-page__button {
      width: 277px;
      height: 80px;
      margin-top: 70px;
      border-radius: 5px;
      background: $color-red-7;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
  }
}
