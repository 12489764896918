@import 'src/styles/custom/variables/variables';

.tile-attendance-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &__legend-box {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    border-radius: 5px;

    &--red {
      background-color: $color-red;
    }

    &--black {
      background-color: $color-black;
    }

    &--green {
      background-color: $color-green-1;
    }

    &--orange {
      background-color: $color-orange-1;
    }

    &--yellow {
      background-color: $color-orange-1;
    }

    &--grey {
      background-color: $color-grey-2;
    }
  }

  &__date-small {
    font-size: 14px;
    font-weight: 600;
  }

  &__legend-text {
    margin-left: 15px;
    font-size: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__month-wrap {
    .select--date {
      width: 251px;

      @include no-font-scaling-parent {
        width: 102px;
      }
    }
  }

  &__date-label {
    font-size: 36px;
    font-weight: 600;

    @include no-font-scaling-parent {
      font-size: 16px;
    }
  }

  &__date-input-small {
    display: inline-block;
    min-width: 93px;
    max-width: 50ch;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__table-tv-screen {
    th {
      width: 78.4px;
    }
  }

  &__table-desktop-screen {
    th {
      width: 80px;
    }
  }

  &__status-text {
    font-size: 36px;
    font-weight: 600;
  }

  &__today {
    margin-right: 35px;
  }

  &__nav {
    display: flex;

    .nav__arrow {
      margin-right: 35px;
    }
  }

  &__status-indicator {
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    padding: 2px;
    border: 2px solid $color-grey-2;
    border-radius: 50%;
    background-clip: content-box;

    &--green {
      background-color: $color-green-1;
    }

    &--red {
      background-color: $color-red;
    }
  }

  th {
    font-weight: 600;

    // stylelint-disable-next-line
    &.tile-attendance-list--disabled {
      color: $color-grey-2;
    }
  }


  &--small {
    .tile-attendance-list__title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 24px;
      font-weight: 600;
    }

    .tile-attendance-list__legend-text {
      margin-left: 5px;
      font-size: 14px;
    }

    .tile-attendance-list__status-text {
      font-size: 10px;
    }

    .tile-attendance-list__status-indicator {
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      padding: 1px;
      border: 1px solid $color-grey-2;
    }

    .tile-attendance-list {
      &__legend-box {
        width: 10px;
        height: 10px;
      }

      &__legend-text {
        font-size: 10px;
      }
    }
  }
}
