@import 'src/styles/custom/variables/variables';

.tile-required-material {
  height: 100%;
  overflow-y: auto;

  &__sub-title {
    font-size: 36px;
  }

  &--small {
    .tile-required-material__title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 24px;
    }

    .tile-required-material__sub-title {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  &__table {
    th {
      width: auto;
      vertical-align: top;

      &:first-child {
        width: 197px;
      }

      &:nth-child(3) {
        width: 9%;
      }

      &:nth-child(5) {
        width: 8%;
      }

      &:nth-child(6) {
        width: 6%;
      }

      &:nth-child(7) {
        width: 7%;
      }

      &:nth-child(8) {
        width: 4%;
      }

      &:nth-child(9) {
        width: 6%;
      }

      &:nth-child(10) {
        width: 6%;
      }
    }

    &.table--small {
      th {
        height: 50px;
        padding: 10px 10px 5px;
      }

      th:first-child {
        width: 54px;
      }

      .table-input__text {
        padding: 0 10px;
      }
    }
  }
}
