@import 'src/styles/custom/variables/variables';
@import 'src/styles/vendor/bootstrap/bootstrap-variables';

.tile-power-bi {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;

  &__content {
    position: relative;
    flex-grow: 1;
    height: 100%;
    background-color: $color-white;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  }
}
