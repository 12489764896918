@import 'src/styles/custom/variables/variables';

.tile-presence {
  &__legend-text {
    margin-left: 15px;
    font-size: 24px;

    &-title {
      font-weight: 600;
    }
  }

  &--small {
    .tile-presence__title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 10px;
    }

    .tile-presence__legend-box {
      width: 10px;
      height: 10px;
      margin-left: 10px;
      border-radius: 3px;
    }

    .tile-presence__legend-text {
      margin-left: 5px;
      font-size: 10px;
    }

    .tile-presence__info-text {
      font-size: 10px;
    }
  }
}
