@import 'src/styles/custom/variables/variables';

.tile-detail {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 112px);
  padding-top: 45px;
  padding-bottom: 85px;

  @include no-font-scaling-parent {
    height: calc(100vh - 47px);
    padding-top: 25px;
    padding-bottom: 40px;
  }

  &__content {
    height: 100%;
    overflow-y: auto;

    @include no-font-scaling-parent {
      .container-fluid {
        padding: 0 40px;
      }
    }

    .container-fluid {
      padding: 0 150px;
    }
  }
}
