$color-black: #000;
$color-black-2: #494949;

$color-white: #fff;

$color-red: #e30018;
$color-red-2: #b21013;
$color-red-3: #db061c;
$color-red-4: #d3282b;
$color-red-5: #c71e21;
$color-red-6: #a30e11;
$color-red-7: #f27577;
$color-red-7: #e30613;
$color-red-8: #ed7c7e;
$color-red-9: #c10f13;
$color-red-10: #fcdddd;
$color-red-11: #f39393;
$color-red-12: #8e1313;

$color-green-1: #219653;
$color-green-2: #63a17d;
$color-green-3: #3f9c66;
$color-green-4: #96caab;
$color-green-5: #def3e7;

$color-grey-1: #3e3e3e;
$color-grey-2: #bdbdbd;
$color-grey-3: #ecede8;
$color-grey-4: #f6f6f6;
$color-grey-5: #e6e6e6;
$color-grey-6: #8a8a8a;
$color-grey-7: #494949;
$color-grey-8: #c4c4c4;
$color-grey-9: #d8d8d8;
$color-grey-10: #e0e0e0;

$color-orange-1: #f2c94c;
$color-orange-2: #fdebb4;

$color-blue: #2f80ed;
$color-blue-2: #e8f2fc;
$color-blue-3: #0763de;
