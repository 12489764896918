@import 'src/styles/custom/variables/variables';

.tile-completion-date-order-amount-desktop.no-font-scaling {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  .tile-completion-date-order-amount-desktop__header {
    padding: 5px 15px;
    border-bottom: 1px solid $color-grey-3;
    background-color: $color-grey-4;
    font-size: 14px;
    font-weight: 600;
  }

  .tile-completion-date-order-amount-desktop__order-amount {
    margin-top: 10px;
  }
}
