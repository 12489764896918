@import 'src/styles/custom/variables/colors';

.history-dropdown.no-font-scaling {
  .history-dropdown__selected {
    text-decoration: underline;
  }

  &--desktop-screen {
    width: 99px;
    margin-bottom: 11px;

    .history-dropdown__icon {
      width: 6px;
      height: 4px;
      margin-left: 2px;
    }

    .history-dropdown__title {
      color: $color-white;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-decoration: underline;
    }

    .dropdown-menu {
      width: 99px;
      min-width: 99px;
      padding: 12px 3px;
      border-radius: 5px;
      border-color: $color-red-9;
      background-color: $color-red-9;
    }

    .dropdown-item {
      margin-bottom: 10px;
      padding: 0;
      color: $color-white;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-align: center;

      &:last-child {
        margin: 0;
      }
    }

    .history-dropdown__dropdown-menu {
      transform: translateY(23px) !important;
    }
  }

  &--tv-screen {
    width: 190px;
    margin-bottom: 22px;

    .history-dropdown__icon {
      width: 16px;
      height: 10px;
      margin-left: 15px;
    }

    .history-dropdown__title {
      color: $color-white;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-decoration: underline;
    }

    .dropdown-menu {
      width: 190px;
      min-width: 190px;
      padding: 22px 35px;
      border-radius: 5px;
      border-color: $color-red-9;
      background-color: $color-red-9;
    }

    .dropdown-item {
      margin-bottom: 26px;
      padding: 0;
      color: $color-white;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      text-align: center;

      &:last-child {
        margin: 0;
      }
    }

    .history-dropdown__dropdown-menu {
      transform: translateY(45px) !important;
    }
  }

  .dropdown-item:hover {
    background-color: $color-red-9;
    color: $color-white;
    text-decoration: underline;
  }
}
