@import 'src/styles/custom/variables/variables';

.textarea {
  margin-bottom: 35px;

  &--error {
    margin-bottom: 5px;

    .textarea__input {
      border-color: $color-red-7;
    }
  }

  &__label {
    display: block;
    margin-bottom: 10px;
    color: $color-grey-1;
    font-size: 28px;
    font-weight: 600;
    line-height: 1;
  }

  &__textarea {
    display: block;
    width: 100%;
    height: 150px;
    padding: 12px 26px;
    border: 2px solid $color-grey-2;
    color: $color-grey-1;
    font-size: 36px;
    font-weight: normal;
    line-height: 1;
    resize: none;
  }

  &__error {
    margin-bottom: 11px;
    border-color: $color-red-7;
    font-size: 24px;
  }
}
