@import 'src/styles/custom/variables/variables';

.tile-sick-leave {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &__title {
    margin-bottom: 160px;
    font-size: 68px;
    font-weight: 600;
  }

  &__date-small {
    font-size: 14px;
    font-weight: 600;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__date-label {
    font-size: 36px;
    font-weight: 600;
  }

  &__date-input {
    height: 80px;
    border: 2px solid $color-grey-2;
    border-radius: 10px;
    color: $color-grey-1;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
  }

  &__date-input-small {
    display: inline-block;
    min-width: 93px;
    padding: 0 10px;
    border-bottom: 1px solid $color-grey-1;
  }

  &--small {
    .tile-sick-leave__title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 24px;
    }
  }

  &__indicator {
    width: 80px;
    height: 80px;
    border: 1px solid $color-grey-2;
    border-radius: 50%;
    background-color: $color-grey-4;
    vertical-align: middle;
    cursor: pointer;

    &--red {
      background-color: $color-red;
    }

    &--green {
      background-color: $color-green-1;
    }

    &--checked {
      background-color: $color-black;
    }

    &--small {
      width: 20px;
      height: 20px;
    }
  }

  &__table {
    table {
      border: 2px solid $color-grey-2;
    }

    th {
      width: 140px;
      border-right: 2px solid $color-grey-2;
      border-bottom: 2px solid $color-grey-2;
      line-height: 100%;
      text-align: center;
      word-break: break-word;

      &:nth-child(1) {
        width: auto;
      }

      &:nth-child(11) {
        width: 600px;
      }

      &:nth-child(15) {
        width: 600px;
      }
    }

    td {
      height: 164px;
      border-top: 2px solid $color-grey-2;
      border-right: 2px solid $color-grey-2;
      font-size: 36px;
      text-align: center;

      &:nth-child(1) {
        border-top: 2px solid $color-grey-2;
        border-right: 2px solid $color-grey-2;
        font-weight: 600;
        text-align: center;
      }
    }
  }

  .table--small {
    .table__subtitle {
      font-size: 8px;
    }

    th {
      width: 50px;
      height: 33px;
      border-right: 1px solid $color-grey-2;
      border-bottom: 1px solid $color-grey-2;
      font-size: 12px;
      word-break: break-word;

      &:nth-child(1) {
        width: auto;
      }

      &:nth-child(11) {
        width: 270px;
      }

      &:nth-child(15) {
        width: 270px;
      }
    }

    td {
      height: 55px;
      font-size: 12px;
      font-weight: 600;
      text-align: left;
    }

    tr {
      height: 30px;
    }
  }
}
