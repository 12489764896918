@import 'src/styles/custom/variables/colors';

.notification {
  &--success.toast {
    border-left: 3px solid $color-green-1;

  }

  &--error.toast {
    border-left: 3px solid $color-red;

  }

  &--warning.toast {
    border-left: 3px solid $color-orange-1;

  }

  &--info.toast {
    border-left: 3px solid $color-blue;
  }

  &__body {
    display: flex;
    align-items: center;
  }

  &__status-icon {
    display: inline-flex;
    margin-right: 30px;
    overflow: visible;
  }

  &__content {
    margin-right: 30px;
  }

  &__close {
    margin-left: auto;
  }
}
