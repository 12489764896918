@import 'src/styles/custom/variables/variables';

.project-area-row-title {
  &__text {
    transform: rotate(180deg);
    color: $color-white;
    font-size: 24px;
    font-weight: 600;
    writing-mode: vertical-rl;

  }
}
