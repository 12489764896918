@import 'src/styles/custom/variables/variables';

.tile-standing-rules-desktop-screen.no-font-scaling {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .tile-standing-rules-desktop-screen__header {
    position: relative;
    padding: 5px 0;
    border-bottom: 1px solid $color-grey-3;
    background: $color-grey-4;
  }

  .tile-standing-rules-desktop-screen__header-content {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 115.5%;
  }

  .tile-standing-rules-desktop-screen__button-tooltip {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 16px;
    height: 16px;
    padding: 0;
    transform: translateY(-50%);
    border: none;
    border-radius: 3px;
    background: transparent;

    // stylelint-disable-next-line
    .icon rect, line {
      stroke: $color-grey-2;
    }


    .icon:hover {
      // stylelint-disable-next-line
      rect, line {
        stroke: $color-grey-1;
      }
    }
  }

  .tile-standing-rules-desktop-screen__title {
    color: $color-grey-6;
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;
  }

  .tile-standing-rules-desktop-screen__text {
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;
  }

  .bootstrap-tooltip-inner {
    max-width: 200px;
  }
}
