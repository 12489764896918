@import 'src/styles/custom/variables/variables';

.tile-measures-escalation {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;

  @include no-font-scaling-parent {
    .icon {
      width: 18px;
      height: 18px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
  }

  &__measure,
  &__escalation {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 50px;
    overflow: hidden;
  }

  &__escalation {
    padding-bottom: 50px;
  }


  .tile-measure,
  .tile-escalation {
    overflow-y: hidden;

    th {
      border-top: none;
      border-bottom: none;
    }

    th::after,
    th::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
    }

    th::before {
      top: 0;
      border-top: 1px solid $color-grey-4;
    }

    th::after {
      bottom: -1px;
      border-bottom: 1px solid $color-grey-3;
    }

    // stylelint-disable-next-line
    tr:first-of-type td {
      border-top: none;
    }

    // stylelint-disable-next-line
    tr:last-of-type td {
      border-bottom: none;
    }

    &__table-wrap {
      flex-grow: 0;
      border: 2px solid $color-grey-2;
    }

    &__table {
      border: none;

      th {
        position: sticky;
        z-index: 1;
        top: 0;
      }
    }

    // stylelint-disable-next-line
    .table--small .table__title {
      padding: 0 5px;
    }
  }

  .tile--horizontal & {
    .tile-measures-escalation__content {
      flex-direction: row;
      justify-content: space-between;
    }

    .tile-measures-escalation__measure {
      flex-basis: calc(50% - 10px);
      height: 100%;
      margin-bottom: 0;
    }

    .tile-measures-escalation__escalation {
      flex-basis: calc(50% - 10px);
      height: 100%;
      margin-bottom: 0;
    }
  }
}
