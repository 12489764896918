@import 'src/styles/custom/variables/variables';

.notifications {
  position: fixed;
  z-index: 10;
  top: 112px; // header logo height
  left: 50%;
  transform: translateX(-50%);

  .toast {
    max-width: none;
    background-color: $color-white;
  }
}
