@import 'src/styles/custom/variables/variables';

.board {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  background-color: $color-grey-3;

  &--white {
    background-color: $color-white;
  }
}
