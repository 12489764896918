@import 'src/styles/custom/variables/variables';

.tile-table {
  height: 100%;
  overflow-y: auto;

  &__sub-title {
    font-size: 36px;

    @include no-font-scaling-parent {
      font-size: 16px;
      line-height: 18.48px;
    }
  }

  &__status-text {
    font-size: 36px;
    font-weight: 600;
  }

  &__status-indicator {
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    padding: 2px;
    border: 2px solid $color-grey-2;
    border-radius: 50%;
    background-clip: content-box;

    &--green {
      background-color: $color-green-1;
    }

    &--red {
      background-color: $color-red;
    }
  }

  &__table {
    th {
      width: auto;

      &:first-child {
        width: 197px;
        border-right: 1px solid $color-grey-2;
      }
    }

    &.table--small {
      th {
        height: 50px;
        padding: 4px;
      }

      .table-input__text {
        padding: 0 10px;
      }
    }
  }

  .table-no-border {
    border: none !important;

    thead,
    tbody {
      border-right: 2px solid $color-grey-2;
      border-left: 2px solid $color-grey-2;
    }

    thead {
      border-top: 2px solid $color-grey-2;
    }

    tbody {
      border-bottom: 2px solid $color-grey-2;
    }

    tfoot {
      border-right: 2px solid $color-grey-2;
      // stylelint-disable-next-line
      td:first-child {
        border-left: 2px solid $color-grey-2;
      }

      // stylelint-disable-next-line
      td {
        border-bottom: 2px solid $color-grey-2;
      }
    }
  }

  @for $i from 1 through 35 {
    .w-#{$i} {
      width: round(percentage($i/100)) !important;
    }
  }

  &--small {
    .tile-table__title {
      margin-bottom: 5px;
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
    }

    .tile-table__sub-title {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1.15;
    }

    .tile-table__status-text {
      font-size: 10px;
    }

    .tile-table__status-indicator {
      width: 24px;
      height: 24px;
      padding: 1px;
      border: 1px solid $color-grey-2;
    }
  }


  &__days-inner {
    display: flex;
  }
}
