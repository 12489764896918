.tile-measures-escalation-desktop.no-font-scaling {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tile-measures-escalation-desktop__measures {
    flex-grow: 0;
    flex-shrink: 0;
    height: 50%;
    overflow: hidden;
  }

  .tile-measures-escalation-desktop__escalation {
    flex-grow: 0;
    flex-shrink: 0;
    height: 50%;
    padding-top: 10px;
    overflow: hidden;
  }

  .tile-escalation,
  .tile-measure {
    overflow: hidden;
  }

  .table {
    overflow: auto;
  }
}
