@import 'src/styles/custom/variables/variables';

.tile-completion-date-order-amount {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;

  &__title {
    font-size: 36px;
    font-weight: 600;
  }

  &__sub-title {
    font-size: 36px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
  }

  &__completion-date,
  &__order-amount {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 50px;
    overflow: hidden;
  }


  .tile-completion-date,
  .tile-order-amount {
    overflow-y: hidden;

    th {
      border-top: none;
      border-bottom: none;
    }

    th::after,
    th::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
    }

    th::before {
      top: 0;
      border-top: 1px solid $color-grey-4;
    }

    th::after {
      bottom: -1px;
      border-bottom: 1px solid $color-grey-3;
    }

    // stylelint-disable-next-line
    tr:first-of-type td {
      border-top: none;
    }

    // stylelint-disable-next-line
    tr:last-of-type td {
      border-bottom: none;
    }

    &__table-wrap {
      flex-grow: 0;
      border: 1px solid $color-grey-2;
    }

    &__table {
      border: none;

      th {
        position: sticky;
        z-index: 1;
        top: 0;
      }
    }

    .table__title {
      padding: 0 20px;
    }

    .table__text {
      padding: 0 15px;
    }
  }

  &--small {
    .tile-completion-date-order-amount__title {
      font-size: 24px;
    }

    .tile-completion-date-order-amount__sub-title {
      max-width: 50%;
      font-size: 14px;
      line-height: 1.2;
    }

    .tile-completion-date,
    .tile-order-amount {
      .table__title {
        padding: 0 10px;
      }
    }
  }
}
