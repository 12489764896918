@import 'src/styles/custom/variables/variables';

.background {
  position: relative;
  background-color: $color-grey-1;

  &--white {
    background-color: $color-white;
  }

  &--grey {
    background-color: $color-grey-1;
  }

  &--green {
    background-color: $color-green-1;
  }

  &--green3 {
    background-color: $color-green-3;
  }

  &--red {
    background-color: $color-red;
  }

  &--red3 {
    background-color: $color-red-3;
  }
}
