@import 'src/styles/custom/variables/variables';

.tile-to-do-desktop-screen.no-font-scaling {
  height: 100%;
  overflow-y: auto;
  border: 1px solid $color-grey-2;

  .table {
    flex-grow: 1;
    border: none;
  }

  table {
    height: 100%;
  }

  tr {
    height: auto;
    padding: 0 13px;
    border-top: 1px solid $color-grey-3;
  }

  th,
  td {
    padding-right: 14px;
    padding-left: 15px;
    border: none;
  }

  th {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
  }

  td {
    min-height: 24px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;

    &:first-child {
      padding-right: 2px;
    }

    &:last-child {
      width: 30.6px;
      padding-right: 14px;
      padding-left: 2px;
    }
  }

  &__status {
    width: 12px;
    height: 12px;
  }
}
