@import 'src/styles/custom/variables/variables';

.tile-attendance-list-desktop-screen.no-font-scaling {
  height: 100%;
  border: none;

  .table {
    flex-grow: 1;
    border: none;
  }

  tr {
    height: 18px;
  }

  &__tr-height {
    &--gfboard {
      height: 18px;
    }

    &--brlboard {
      height: 18px;
    }
  }

  th {
    padding: 5px 15px;
    border-top: none;
    border-bottom: 1px solid $color-grey-3;
    font-size: 14px;
    font-weight: 600;

    .status {
      position: absolute;
      top: 8px;
      right: 19px;
      box-sizing: border-box;
      width: 19px;
      height: 19px;
      padding: 1px;
      border: 1px solid $color-grey-2;
      border-radius: 50%;
      background-clip: content-box;

      &-red {
        background-color: $color-red;
      }

      &-green {
        background-color: $color-green-1;
      }
    }
  }

  td {
    padding: 0;
    border: none;
    font-size: 12px;

    &:nth-child(1) {
      width: 148px;
      padding-left: 15px;
    }

    &:nth-child(2) {
      width: 89px;
      padding-right: 15px;
      text-align: right;
    }
  }
}
