.pdf-button-link.no-font-scaling {
  position: absolute;
  top: 8px;
  right: 16px;
  width: 15px;
  height: 15px;
  padding: 0;
  border: none;
  background: transparent;
}
