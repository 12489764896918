@import 'src/styles/custom/variables/variables';

.back-office-capacity-subcontractors {

  @include no-font-scaling {
    th {
      height: 40px;
      padding: 8px 10px;
    }

    td {
      height: 40px;
      padding: 7px 6px;
    }

    th,
    td {
      &:first-child {
        width: 3.6%;
      }

      &:nth-child(2) {
        width: 35.9%;
      }

      &:nth-child(3) {
        width: 52.9%;
      }

      &:nth-child(4) {
        width: 5.6%;
      }
    }
  }

  .table {
    // stylelint-disable-next-line
    tr:first-child th {
      border-top: none;
    }

    th {
      padding: 24px 27px 23px;
      font-weight: 600;
    }

    td {
      height: 100px;
      padding: 23px 10px;
    }

    td,
    th {
      &:first-child {
        width: 3.3%;
      }

      &:nth-child(2) {
        width: 30.2%;
      }

      &:nth-child(3) {
        width: 60.9%;
      }

      &:nth-child(4) {
        width: 5.6%;
      }
    }
  }

  .table-input {
    height: 100%;
  }

  .table-input__input {
    height: 100%;
  }

  .table-input__text {
    height: 100%;
  }

  &__text-empty {
    margin-top: 117px;
    color: $color-grey-6;
    font-size: 36px;
    text-align: center;
  }
}
