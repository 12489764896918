@import 'src/styles/custom/variables/variables';

.measure-form {
  width: 85%;
  height: 1388px;
  margin: auto;

  &__title {
    margin-top: 98px;
    margin-bottom: 69px;
    font-size: 68px;
    font-weight: 600;
    line-height: 1.1;
    text-align: center;
  }

  &__indicator-label {
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    line-height: 1;
  }

  &__group {
    margin-top: 35px;
  }

  &__indicator-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 87px;

    .kvp-indicator {
      margin-top: 10px;
    }
  }

  &__row {
    display: flex;

    &--radio {
      margin-bottom: 10px;

      @include no-font-scaling-parent {
        margin-bottom: 4px;
      }
    }
  }

  &__row-item {
    &--50 {
      flex-basis: 50%;
    }
  }

  &__buttons {
    margin-top: 94px;
    margin-bottom: 79px;
  }
}
