// stylelint-disable selector-no-qualifying-type
.heading {
  margin: 0;
  font-size: 35px;
  font-weight: 600;
  line-height: 44px;
}

h1.heading {
  font-size: 52px;
}

h2.heading {
  font-size: 36px;
}

h3.heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}

h4.heading {
  font-size: 16px;
  line-height: 16px;
}

h5.heading {
  font-size: 16px;
  line-height: 16px;
}

h6.heading {
  font-size: 14px;
  line-height: 14px;
}
