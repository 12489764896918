@import 'src/styles/custom/variables/variables';
@import 'src/styles/vendor/bootstrap/bootstrap-variables';

.board-nav {
  &__dots {
    display: flex;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__dot {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 50px;
    border-radius: 50%;
    background-color: $color-grey-6;

    &--active {
      background-color: $color-red-7;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .nav__arrow {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: $grid-gutter-width;
    background-color: $color-grey-2;

    @include no-font-scaling-parent {
      left: $desktop-grid-gutter-width;
    }

    &:hover {
      background-color: $color-grey-6;
      box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
    }

    &--right {
      right: $grid-gutter-width;
      left: auto;

      @include no-font-scaling-parent {
        right: $desktop-grid-gutter-width;
        left: auto;
      }
    }
  }
}
