@import 'src/styles/custom/variables/variables';

.table-status {
  display: flex;
  width: 65px;
  height: 65px;
  margin: auto;
  padding: 0;
  border: 1px solid $color-grey-2;
  border-radius: 10%;
  outline: none;
  vertical-align: middle;
  cursor: pointer;

  &--circle {
    width: 70px;
    height: 70px;
    margin: 5px auto;
    border-radius: 50%;

    @include no-font-scaling-parent {
      width: 25px;
      height: 25px;
    }
  }

  &--sm {
    width: 10px;
    height: 10px;
    border: none;

    &.table-status--circle {
      width: 15px;
      height: 15px;
    }
  }

  &--lg {
    width: 75px;
    height: 75px;
  }

  &--red {
    background-color: $color-red;
  }

  &--green {
    background-color: $color-green-1;
  }

  &--orange {
    background-color: $color-orange-1;
  }

  &--yellow {
    background-color: $color-orange-1;
  }

  &--grey {
    background-color: $color-grey-2;
  }

  &--black,
  &--checked {
    background-color: $color-black;
  }

  &--white,
  &--undefined,
  &--unchecked {
    border: 1px solid $color-grey-2;
    background-color: $color-white;
  }

  &--sm.no-font-scaling {
    width: 12px;
    height: 12px;
    margin: 0;

    &:not(.table-status--circle) {
      border-radius: 2px;
    }
  }
}
