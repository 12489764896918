@import 'src/styles/custom/variables/variables';

.tile-desktop-screen.no-font-scaling {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .table {
    flex-grow: 1;
    border: none;
  }

  table {
    height: 100%;
  }

  tr {
    height: auto;
    padding: 0 13px;
    border-top: 1px solid $color-grey-3;
  }

  th,
  td {
    padding-right: 14px;
    padding-left: 15px;
    border: none;
  }

  th {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
  }

  td {
    min-height: 24px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;

    // stylelint-disable selector-no-qualifying-type
    &.date {
      min-width: 43px;
      color: $color-grey-6;
    }

    &.shrink {
      padding-right: 0;
      padding-left: 0;
    }

    &:last-child {
      padding-right: 15px;
    }

    // stylelint-enable selector-no-qualifying-type
  }

  .status {
    position: absolute;
    top: 8px;
    right: 15px;
    box-sizing: border-box;
    width: 19px;
    height: 19px;
    padding: 1px;
    border: 1px solid $color-grey-2;
    border-radius: 50%;
    background-clip: content-box;

    &-red {
      background-color: $color-red;
    }

    &-green {
      background-color: $color-green-1;
    }
  }

  .table-text-bold {
    font-weight: 600;
  }

  .number-pp-input {
    width: 57px;
    padding: 0;
    font-size: 12px;
    line-height: 1.1;

    &__wrap {
      height: 20px;
      padding: 1px 5px;
    }

    &__icon {
      flex-shrink: 0;
      width: 7px;
      height: 7px;
    }

    &__pp {
      margin: 0 0 0 4px;
    }
  }
}
