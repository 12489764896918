@import 'src/styles/custom/variables/variables';

.tile-week-topics {
  height: 100%;
  overflow-y: auto;

  &__sub-title {
    font-size: 36px;
  }

  &--small {
    .tile-week-topics__title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 24px;
    }

    .tile-week-topics__sub-title {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  &__table {
    th {
      width: auto;

      &:first-child {
        width: 197px;
        border-right: 1px solid $color-grey-2;
      }
    }

    &.table--small {
      th {
        height: 50px;
        padding: 10px 10px 5px;
        vertical-align: top;
      }

      .first-cell {
        width: 54px;
        border-right: 1px solid $color-grey-2;
      }

      .table-input__text {
        padding: 0 10px;
      }
    }
  }
}
