@import 'src/styles/custom/variables/variables';

.tile-occupational-safety {
  height: 100%;
  overflow-y: auto;

  &__sub-title {
    font-size: 36px;
  }

  &__info {
    margin-bottom: 50px;
    text-align: right;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 60px;
  }

  &__wrap-left {
    display: flex;
    flex: 1;
  }

  &__left {
    width: 40%;
  }

  &__right {
    width: calc(60% - 75px);
    margin-left: 75px;
    padding: 86px;
    background-color: $color-grey-10;
  }

  &__image-wrap {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__image {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
  }

  &__nav-wrap {
    display: flex;
    width: 100%;
  }

  &__month {
    width: 50%;
  }

  &__nav {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }

  &__nav-wrap {
    font-size: 52px;
    font-weight: 600;
  }

  &__table-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    padding: 101px;
    background-color: $color-grey-10;
  }

  &__table {
    td {
      width: 143px;
      height: 143px;
      text-align: center;
      vertical-align: center;
    }
  }

  &__td {
    padding: 15px;
    border: 1px solid $color-grey-2;
    background-color: $color-white;

    &--today {
      background-color: $color-grey-1;
    }
  }

  &__date {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    background-color: $color-white;
    font-weight: 600;

    &:disabled {
      color: $color-grey-2;
    }

    &--yellow {
      background-color: $color-orange-1;
      color: $color-white;
    }

    &--green {
      background-color: $color-green-1;
      color: $color-white;
    }

    &--red {
      background-color: $color-red-2;
      color: $color-white;
    }
  }

  &__td-grey {
    border: 1px solid $color-grey-2;
    background-color: $color-grey-4;
  }

  .nav__arrow--right {
    margin-left: 35px;
  }

  &__table-info {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  &__table-info-item {
    display: flex;
    align-items: center;
    margin-bottom: 33px;
    font-size: 52px;
    line-height: 1.15;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__table-info-box {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-right: 60px;
    border-radius: 10px;
    background-color: $color-orange-1;

    &--red {
      background-color: $color-red;
    }
  }

  &__table-info-number {
    margin-right: 51px;
    font-weight: 600;
  }

  &--small {
    .tile-occupational-safety__title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 24px;
    }

    .tile-occupational-safety__sub-title {
      margin-bottom: 10px;
      font-size: 14px;
    }

    .tile-occupational-safety__wrap {
      padding-bottom: 12px;
    }

    .tile-occupational-safety__info {
      margin-bottom: 0;
      line-height: 0.3;
    }

    .tile-occupational-safety__month {
      font-size: 16px;
    }

    .tile-occupational-safety__table-wrap {
      flex: 1;
      margin-top: 5px;
      padding: 27px;
    }

    .tile-occupational-safety__left {
      display: flex;
      width: 100%;
    }

    .tile-occupational-safety__left-top {
      display: flex;
      flex-direction: column;
      width: 60%;
    }

    .tile-occupational-safety__table {
      td {
        width: 37px;
        height: 37px;
        padding: 0;
        font-size: 14px;
        text-align: center;
        vertical-align: center;
      }
    }

    .tile-occupational-safety__table-info {
      margin-top: 25px;
    }

    .tile-occupational-safety__table-info-item {
      margin-bottom: 8px;
      font-size: 16px;
    }

    .tile-occupational-safety__table-info-box {
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      margin: 0 18px;
    }

    .tile-occupational-safety__table-info-number {
      margin: 0 14px 0 0;
    }
  }
}
