@import 'src/styles/custom/variables/variables';

.tile-process-confirmation.no-font-scaling {
  display: flex;
  flex-direction: column;
  line-height: 15px;

  .table {
    flex-grow: 1;
    border: none;

    td {
      border: none;
    }
  }

  table {
    height: 100%;
  }

  tr {
    height: 41px;
  }

  td {
    padding: 0 15px;
    border: none;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
  }

  tr:first-child td {
    padding-top: 2px;
  }

  tr:last-child td {
    padding-bottom: 2px;
  }

  .tile-process-confirmation__project-status {
    width: 12px;
    height: 12px;
    margin: 0 4px 0 0;
    border: none;
    border-radius: 2px;

    &--red {
      background-color: $color-red;
    }

    &--green {
      background-color: $color-green-1;
    }

    &--undefined {
      border: 1px solid $color-grey-2;
      background-color: $color-white;
    }
  }
}
