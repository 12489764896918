@import 'src/styles/custom/variables/variables';

.tile-project-overview {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;

  &:hover {
    .tile-project-overview__add-button {
      display: inline-block;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    font-size: 18px;

    &--desktop-screen {
      height: 160px;
    }

    &--tv-screen {
      height: 81px;
    }
  }

  &__header-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 13);
    border-right: 1px solid $color-grey-6;
    text-align: center;

    &:hover {
      color: $color-black;
    }

    &:last-child {
      border-right: none;
    }
  }

  &__link {
    color: $color-black;

    &:focus,
    &:hover {
      color: $color-black;
      text-decoration: none;
    }
  }

  &__description {
    display: flex;
    height: 160px;
    border-top: 1px solid $color-grey-6;
    border-bottom: 1px solid $color-grey-6;
  }

  &__description-item {
    position: relative;
    width: calc(100% / 13);
    border-right: 1px solid $color-grey-6;
    word-break: break-word;

    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      text-align: center;
    }

    &:last-child {
      border-right: none;
    }
  }

  &__description-item-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 10px;
    overflow: hidden;
  }

  &__row {
    display: flex;
    height: 163px;
    border-bottom: 1px solid $color-grey-6;
  }

  &__row-item {
    position: relative;
    width: calc(100% / 13);
    border-right: 1px solid $color-grey-6;
    font-weight: normal;
    word-break: break-word;

    &--green {
      background-color: $color-green-5;
      color: $color-black;
    }

    &--red {
      background-color: $color-red-10;
      color: $color-black;
    }

    &--center {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-grey-3;
      color: $color-grey-1;
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      &:hover {
        text-decoration: none;
      }
    }

    &:last-child {
      border-right: none;
    }
  }

  &__add-button {
    display: none;
    width: 100%;
    padding: 10px;
    background-color: $color-grey-1;
    color: $color-white;
    font-size: 16px;
    font-weight: 600;
  }

  &__small {
    padding: 50px;
  }
}

.tile-project-overview.no-font-scaling {
  font-size: 12px;
  line-height: 1;

  .tile-project-overview {
    &__header-item,
    &__row-item,
    &__description-item {
      overflow: hidden;
      font-size: 12px;
      hyphens: auto;
    }

    &__description-item-inner {
      // stylelint-disable
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      // stylelint-enable
      overflow: hidden;
      font-weight: normal;
    }

    &__row {
      min-height: 90px;
    }
  }

  .table-textarea {
    &__text,
    &__tooltip-inner,
    &__textarea {
      font-size: 12px;
    }

    &__text {
      -webkit-line-clamp: 4;
      max-height: 8ch;
      line-height: 1;

      &.table-textarea__text--center {
        max-height: none;
      }
    }

    &__tooltip-inner,
    &__status-inner {
      width: 20px;
      height: 9px;
    }

    &__add {
      width: 9px;
      height: 9px;
      padding: 1px;

      &:focus {
        outline: none;
      }

      .icon {
        width: 7px;
        height: 7px;
      }
    }
  }
}
