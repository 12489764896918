@import 'src/styles/custom/variables/variables';

.number-pp-input {
  padding: 0 12px;
  font-size: 36px;

  @include no-font-scaling-parent {
    font-size: 16px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2px 13px;
    border: 1px solid $color-blue-3;
    border-radius: 5px;

    @include no-font-scaling-parent {
      justify-content: space-around;
      height: 35px;
    }
  }

  &__icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    transform: rotate(45deg);
    border-radius: 5px;
    background-color: $color-blue-3;

    @include no-font-scaling-parent {
      width: 15px;
      height: 15px;
    }
  }

  &__pp {
    margin: 0 8px 0 16px;
    color: $color-blue-3;
    font-weight: 600;
  }

  &__input {
    width: 3ch;
    border: none;
    font-weight: 600;
    text-align: center;
  }

  &--small {
    padding: 3px 12px;
    font-size: 16px;

    .number-pp-input__wrap {
      padding: 0 4px;
    }

    .number-pp-input__pp {
      margin: 0 4px;
    }

    .number-pp-input__icon {
      width: 10px;
      height: 10px;
    }
  }
}
