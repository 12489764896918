@import 'src/styles/custom/variables/variables';

.table-form-tile-top {
  min-height: 223px;

  @include no-font-scaling-parent {
    min-height: 103px;
  }

  &__info {
    margin-top: 31px;
    font-size: 24px;
    line-height: 1;

    @include no-font-scaling-parent {
      margin-top: 10px;
      font-size: 16px;
    }
  }

  &--small {
    min-height: 0;
    margin: 15px 10px 10px;

    .table-form-tile-top__info {
      margin-top: 0;
    }
  }
}
