.back-office-project-overview {
  .table {
    th {
      padding: 24px 27px;
      font-weight: 600;
    }

    td {
      height: 104px;
      padding: 25px 10px;

      &:first-child {
        width: 4%;
      }

      &:nth-child(2) {
        width: 25%;
      }

      &:nth-child(3) {
        width: 20%;
      }

      &:nth-child(4) {
        width: 15%;
      }

      &:nth-child(5) {
        width: 32%;
      }

      &:nth-child(6) {
        width: 4%;
      }
    }
  }

  .table-input {
    height: 100%;
  }

  .table-input__input {
    height: 100%;
  }

  .table-input__text {
    height: 100%;
  }
}
