@import 'src/styles/custom/variables/variables';

.tile-checkpoints-master-plan {
  &__info {
    line-height: 0.5;
  }


  td {
    width: auto;
    padding: 0;
  }

  th {
    width: auto;

    &:first-child {
      width: 3.5%;

      @include no-font-scaling-parent {
        width: 7.9%;
      }
    }

    &:nth-child(2) {
      width: 78.4%;

      @include no-font-scaling-parent {
        width: 72.6%;
      }
    }

    &:nth-child(3) {
      width: 11.9%;

      @include no-font-scaling-parent {
        width: 12.3%;
      }
    }

    &:nth-child(4) {
      width: 4.2%;

      @include no-font-scaling-parent {
        width: 5.1%;
      }
    }

    &:nth-child(5) {
      width: 2%;

      @include no-font-scaling-parent {
        width: 2.1%;
      }
    }
  }


  .tile-table__table.table--small th {
    height: 37px;
  }

}
