@import 'src/styles/custom/variables/variables';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 225px;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: $color-grey-1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  color: $color-white;
  font-size: 14px;

  &:hover,
  &:focus {
    background-color: $color-black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.22);
    color: $color-white;
    text-decoration: none;
  }

  &--secondary {
    background-color: $color-grey-2;

    &:hover,
    &:focus {
      background-color: $color-grey-6;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.22);
    }
  }

  &--large {
    min-width: 330px;
    min-height: 80px;
    padding: 0 30px;
    font-size: 30px;
    font-weight: 600;
  }

  &--big {
    min-width: 239px;
    min-height: 80px;
    padding: 0 49px;
    font-size: 36px;
    font-weight: 600;

    @include no-font-scaling-parent {
      min-width: 120px;
      min-height: 35px;
      padding: 0 25px;
      font-size: 16px;
    }
  }

  &--small {
    min-width: 140px;
    font-size: 18px;
    font-weight: 600;
  }

  &--red {
    background-color: $color-red-7;

    &:hover,
    &:focus {
      background-color: $color-red-6;
    }
  }
}
