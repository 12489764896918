@import 'src/styles/custom/variables/variables';

.tile-goal-setting.no-font-scaling {
  tr {
    height: auto;
    padding: 0 13px;
    border-top: 1px solid $color-grey-3;
  }

  th,
  td {
    padding-right: 14px;
    padding-left: 15px;
    border: none;
  }

  td {
    min-height: 24px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;

    &:first-child {
      padding-right: 2px;
    }

    &:last-child {
      width: 30.6px;
      padding-right: 14px;
      padding-left: 2px;
    }
  }

  &__status {
    width: 12px;
    height: 12px;

    &--green {
      background-color: $color-green-1;
    }

    &--red {
      background-color: $color-red;
    }

    &--undefined {
      background-color: $color-grey-2;
    }
  }

  .icon:hover {
    // stylelint-disable-next-line
    rect, line, path {
      stroke: $color-grey-1;
    }
  }
}
