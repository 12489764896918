@import 'src/styles/custom/variables/variables';

.board-content {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 45px;

  @include no-font-scaling-parent {
    height: calc(100vh - 47px);
    padding-top: 25px;
  }
}
