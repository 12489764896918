@import 'src/styles/custom/variables/variables';

.tile-vacation {
  &__info-text {
    font-size: 36px;
  }

  &--small {
    .tile-vacation__info-text {
      font-size: 14px;
    }
  }
}
