@import 'src/styles/custom/variables/variables';

.table-form-toolbar {
  .button {
    margin-left: 36px;

    @include no-font-scaling-parent {
      margin-left: 15px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .nav__arrow {
    @include no-font-scaling-parent {
      margin-left: 15px;
    }
  }
}
