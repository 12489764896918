@import 'src/styles/custom/variables/variables';

.tile-planningquality {
  &__info-text {
    margin-left: 15px;
    font-size: 36px;

    &-title {
      font-weight: 600;
    }
  }

  &--small {
    .tile-planningquality__info-text-title {
      font-size: 10px;
    }

    .tile-planningquality__info-text {
      font-size: 10px;
      line-height: 12px;
    }
  }
}
