@import 'src/styles/custom/variables/variables';

.tile-escalation-desktop-screen.no-font-scaling {
  height: 100%;
  border: none;

  .table {
    flex-grow: 1;
    border: none;
  }

  table {
    width: calc(100% + 1px);
    height: auto;
  }

  tr {
    height: 23px;
    border-top: 1px solid $color-grey-3;
    border-bottom: 1px solid $color-grey-3;
  }


  td {
    padding: 2px;
    border: none;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 1;

    &:nth-child(1) {
      padding-left: 15px;
    }

    &:nth-child(2) {
      width: 63px;
      padding-right: 16px;
      color: $color-grey-6;
      font-size: 9px;
    }

    &:nth-child(3) {
      width: 30.6px;
      padding-right: 14px;
    }
  }

  &__indicator {
    width: 15px;
    height: 15px;
    border: 0.8px solid $color-grey-2;
    border-radius: 3px;
  }

}


.tile-escalation-desktop-screen__title.no-font-scaling {
  padding: 5px 0 4px 12px;
  font-size: 14px;
  font-weight: 600;
}
