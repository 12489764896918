@import 'src/styles/custom/variables/variables';

.board-heading.no-font-scaling {
  &--desktop-screen {
    margin-top: 10px;
    margin-bottom: 6px;
    color: $color-white;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }

  &--tv-screen {
    margin-top: 22px;
    margin-bottom: 5px;
    color: $color-white;
    font-size: 36px;
    font-style: normal;
    font-weight: bold;
    line-height: 100%;
  }
}
