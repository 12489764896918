@import 'src/styles/custom/variables/variables';
@import 'src/styles/vendor/bootstrap/bootstrap-variables';

.project-area-overview {
  &__grid {
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: $grid-gutter-width;
    grid-template-columns: 45px repeat(6, 1fr);
    grid-template-rows: 45px 1fr;
    height: 100%;

    &--small {
      padding: 50px;
    }
  }
}
