@import 'src/styles/custom/variables/variables';

.tile-capacity-subcontractor {
  &__note {
    margin: 20px 0 50px;
    line-height: 1.2;
  }

  &__table {
    th {
      position: sticky;
      z-index: 2;
      top: 0;
      height: 57px;
      border: 2px solid $color-grey-2;
      font-weight: 600;

      @include no-font-scaling-parent {
        height: 20px;
        border: 1px solid $color-grey-2;
      }

      &:first-child {
        border-right: 2px solid $color-grey-2;

        // stylelint-disable-next-line
        @include no-font-scaling-parent {
          border-right: 1px solid $color-grey-2;
        }
      }

      &:last-child {
        border-right: 2px solid $color-grey-2;

        // stylelint-disable-next-line
        @include no-font-scaling-parent {
          border-right: 1px solid $color-grey-2;
        }
      }
    }

    th {
      border-top: none;
      border-bottom: none;
    }

    th::after,
    th::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
    }

    th::before {
      top: 0;
      border-top: 2px solid $color-grey-2;

      @include no-font-scaling-parent {
        top: -1px;
        border-top: 1px solid $color-grey-2;
      }
    }

    th::after {
      bottom: -2px;
      border-bottom: 2px solid $color-grey-2;

      @include no-font-scaling-parent {
        bottom: -1px;
        border-bottom: 1px solid $color-grey-2;
      }
    }

    tr:first-of-type td {
      border-top: none;
    }


    tr:last-of-type td {
      border-bottom: none;
    }

    .empty {
      background-color: $color-white !important;

      &::after,
      &::before {
        content: none;
      }
    }

    .bottom {
      top: 80px;
      background-color: $color-white !important;

      @include no-font-scaling-parent {
        top: 32px;
      }
    }

    td {
      border: 2px solid $color-grey-2;

      @include no-font-scaling-parent {
        border: 1px solid $color-grey-2;
      }

      &:last-child {
        border: 2px solid $color-grey-2;

        // stylelint-disable-next-line
        @include no-font-scaling-parent {
          border: 1px solid $color-grey-2;
        }
      }
    }

    tr {
      height: 57px;

      @include no-font-scaling-parent {
        height: 20px;
      }

      &:first-child td {
        border: 2px solid $color-grey-2;

        // stylelint-disable-next-line
        @include no-font-scaling-parent {
          border: 1px solid $color-grey-2;
        }
      }

      &:last-child td {
        border-bottom: 2px solid $color-grey-2;

        // stylelint-disable-next-line
        @include no-font-scaling-parent {
          border-bottom: 1px solid $color-grey-2;
        }
      }
    }

  }

  .table__title {
    padding: 2px 0;
    font-size: 42px;

    @include no-font-scaling-parent {
      font-size: 16px;
    }
  }

  .table-no-border {
    thead {
      border: none;
      border-left: 2px solid $color-grey-2;

      @include no-font-scaling-parent {
        border-left: 1px solid $color-grey-2;
      }
    }

    tbody {
      border: none;
      border-left: 2px solid $color-grey-2;

      @include no-font-scaling-parent {
        border-left: 1px solid $color-grey-2;
      }
    }
  }

  &__wrap {
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__left-table {
    width: 20%;
  }

  &__right-table {
    width: 20%;
  }

  &__info-days-inner {
    display: flex;
  }

  .tile-capacity-subcontractor__info-days {
    top: 80px;
    padding: 2px !important;
    background-color: $color-white !important;

    @include no-font-scaling-parent {
      top: 32px;
    }
  }

  &__info-day {
    display: block;
    width: calc(100% / 6);
    color: $color-grey-6;
    font-weight: normal;
    text-align: center;
  }

  &__number-row {
    display: flex;

    &:last-child {
      .tile-capacity-subcontractor__number {
        border-bottom: none;
      }
    }
  }

  &__number {
    display: block;
    width: calc(100% / 6);
    height: 72px;
    padding: 8px 0 9px;
    border-right: 1px solid $color-grey-2;
    border-bottom: 1px solid $color-grey-2;
    color: $color-grey-1;
    font-weight: 600;
    text-align: center;

    @include no-font-scaling-parent {
      height: 26px;
      padding: 1px 0;
    }

    .table-input__input {
      height: 100%;
      text-align: center;
    }

    .table-input__text {
      text-align: center;
    }

    &:last-child {
      border-right: none;
    }
  }

  &__result {
    color: $color-blue-3;

    &--red {
      color: $color-red-3;
    }
  }

  .tile-capacity-subcontractor__number-row-wrap {
    padding: 0 !important;
  }

  .tile-capacity-subcontractor__number-info {
    padding: 0 !important;
    background-color: $color-grey-4;
  }

  &__number-info-item {
    padding: 8px 0 9px 20px;
    border-bottom: 1px solid $color-grey-2;
    background-color: $color-grey-4;
    color: $color-grey-6;
    font-weight: 600;

    @include no-font-scaling-parent {
      padding: 5px 0 4px 3px;
      font-size: 14px;
      line-height: 1.26;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__add-measure {
    text-align: center;

    svg {
      width: 33px;
      height: 33px;
    }

    path {
      fill: $color-grey-2;
    }
  }

  &__empty-column {
    background-color: $color-grey-4;
  }

  .textarea {
    margin: 0;
  }

  .textarea__textarea {
    height: 203px;
    border: none;

    @include no-font-scaling-parent {
      font-size: 16px;
    }
  }

  .positive {
    color: $color-blue-3;
  }

  .negative {
    color: $color-red-7;
  }

  .nav__arrow {
    @include no-font-scaling-parent {
      width: 32px;
      height: 32px;
    }
  }

  .table--small {
    .nav__arrow {
      width: 23px;
      height: 23px;
    }

    .tile-capacity-subcontractor__number {
      height: 20px;
      padding: 0;
    }

    .tile-capacity-subcontractor__number-info-item {
      padding: 0 0 0 2px;
      text-align: left;
    }

    th {
      height: 23px;
      // position: static;
    }

    tr {
      height: 23px;
    }

    svg {
      width: 11px;
      height: 11px;
    }

    .textarea__textarea {
      height: 60px;
      padding: 3px;
      font-size: 12px;
    }

    .table-input__text {
      padding: 0;
    }

    .table__title {
      font-size: 12px;
    }

    .tile-capacity-subcontractor__info-days {
      top: 27px;
    }

    .bottom {
      top: 27px;
    }
  }
}
