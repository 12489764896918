@import 'src/styles/custom/variables/variables';

.tile-standing-rules {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  font-size: 14px;

  .table-input__input {
    height: 100%;
  }

  .table-input__text {
    height: 100%;
  }

  .table-input {
    height: 100%;
  }

  &__title {
    margin-bottom: 25px;
    font-size: 68px;
  }

  &__sub-title {
    font-size: 36px;
  }

  &__top {
    display: flex;
  }

  &__top-left {
    width: 50%;
    margin-right: 60px;
  }

  &__table-moderation {
    td:first-child {
      width: 30%;
      padding: 0 20px;
      background-color: $color-grey-4;
      font-weight: bold;
    }
  }

  &__table-termin {
    td:not(:first-child) {
      .table-input__text {
        text-align: center;
      }
    }

    td:nth-child(3) {
      width: 15%;
    }

    td:nth-child(5) {
      width: 15%;
    }

  }

  &__top-right {
    width: 50%;
  }

  &__table-right {
    height: 100%;

    table {
      height: calc(100% - 1px);
    }

    td {
      vertical-align: top;

      &:first-child {
        border-right: none;
      }
    }

    ol {
      margin-top: 20px;
      padding-left: 60px;
    }
  }

  .table {
    th {
      padding-left: 20px;
      font-weight: 600;
    }
  }

  &__table-bottom {
    margin-top: 80px;
    font-size: 14px;

    tr {
      height: auto;
    }

    th {
      font-weight: 600;

      &:first-child {
        width: 5%;
        text-align: center;
      }

      &:nth-child(2) {
        width: auto;
      }

      &:nth-child(3) {
        width: 3%;
      }
    }

    td {
      height: 30px;
    }

    .calendar {
      min-width: 100%;
    }
  }

  &--small {
    .table {
      tr {
        height: 23px;
      }

      th {
        height: 23px;
        padding: 0 10px;
        font-size: 16px;
      }

      td {
        height: 23px;
        padding: 0 10px;
        font-size: 16px;
      }
    }

    .tile-standing-rules__table-termin {
      td:first-child {
        width: 40%;
      }
    }

    .tile-standing-rules__table-moderation {
      td:first-child {
        width: 40%;
        background-color: $color-white;
      }
    }

    .tile-standing-rules__top-left {
      margin-right: 10px;
    }

    .tile-standing-rules__table-right {
      ol {
        margin: 5px 0;
        padding-left: 20px;
        line-height: 1.1;
      }
    }

    .tile-standing-rules__title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 24px;
    }

    .tile-standing-rules__sub-title {
      margin-bottom: 0;
      font-size: 24px;
    }

    .tile-standing-rules__table-bottom {
      margin-top: 0;

      th,
      td {
        padding: 2px;
        font-size: 14px;
      }

      th {
        width: auto;
        height: 25px;
      }

      th:nth-child(1) {
        width: 10%;
      }

      th:nth-child(2) {
        width: auto;
      }
    }
  }
}
