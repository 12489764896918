@import 'src/styles/custom/variables/variables';

.tile-measure.no-font-scaling {
  height: 100%;
  border: none;

  .tile-measure__date-display-small {
    color: $color-grey-6;
    font-size: 9px;
    line-height: 8px;
    white-space: nowrap;

    &--gfboard {
      margin-bottom: 2px;
    }

    &--brlboard {
      margin-bottom: 1px;
    }
  }

  .tile-measure__title {
    padding: 5px 0 4px 12px;
    font-size: 14px;
    font-weight: 600;
  }

  .table {
    flex-grow: 1;
    border: none;
  }

  table {
    width: calc(100% + 1px);
    height: auto;
  }

  tr {
    height: 23px;
    border-top: 1px solid $color-grey-3;
    border-bottom: 1px solid $color-grey-3;
    line-height: 14px;
  }

  td {
    padding: 2px;
    border: none;
    font-size: 12px;

    &:nth-child(1) {
      padding-left: 15px;
    }

    &:nth-child(2) {
      width: 63px;
      padding-right: 16px;
    }

    &:last-child {
      width: 30px;
      padding-right: 14px;
    }
  }

  .tile-measure__icon {
    width: 15px;
    height: 15px;
  }
}
