$grid-columns: 25 !default;
$grid-gutter-width: 45px !default;
$desktop-grid-gutter-width: 40px !default;
$grid-row-columns: 6 !default;

// Modal
$modal-backdrop-opacity: 0.2;
$modal-xl: 60%;
$modal-lg: 40%;
$modal-md: 60%;
