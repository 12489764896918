@import 'src/styles/custom/variables/variables';

.pdf-upload {
  display: block;
  width: 50%;
  margin-top: 110px;
  margin-right: auto;
  margin-left: auto;
  background-color: $color-white;

  > * + * {
    margin-top: 55px;
  }

  &__drop-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 350px;
    border: 2px dashed $color-grey-8;
    background: $color-grey-4;
    color: $color-grey-6;
    font-size: 36px;
    text-align: center;
  }

  &__file-details {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 135px;
    padding: 25px 40px;
    background: $color-grey-4;
    color: $color-grey-6;
  }

  &__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1000px;
    background-color: $color-grey-5;
    text-align: center;
  }

  &__preview-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-grey-5;
    color: $color-grey-6;
    font-size: 1rem;
    text-align: center;
  }

  &__preview-empty-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
  }
}

.file-details {
  display: flex;
  align-items: center;
  width: 100%;
  color: $color-grey-1;

  &__icon {
    width: 72px;
    height: 85px;
  }

  &__content {
    flex-grow: 1;
    margin-left: 50px;
  }


  &__name {
    margin-bottom: 25px;
    font-size: 36px;
    font-weight: bold;
  }

  &__details {
    margin: 0;
    font-size: 26px;
  }

  .progress {
    height: 12px;
  }
}
