@import 'src/styles/custom/variables/variables';
@import 'src/styles/vendor/bootstrap/bootstrap-variables';

// Modal
.modal-content {
  border: none;
  border-radius: 0;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.container-fluid {
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;
}

.no-font-scaling {
  .container-fluid {
    padding-right: $desktop-grid-gutter-width;
    padding-left: $desktop-grid-gutter-width;
  }
}
