@import 'src/styles/custom/variables/variables';

.tile-capacity-subcontractor-desktop-screen.no-font-scaling {
  .first-cell {
    border-right: 1px solid $color-grey-3;
  }

  &.tile-desktop-screen {
    td {
      height: 19px;
      padding: 0 5px;
    }
  }

  tr {
    border-bottom: 1px solid $color-grey-3;
  }

  .tile-capacity-subcontractor-desktop-screen__header td:not(:first-child) {
    color: $color-grey-6;
  }

  .positive {
    color: $color-blue-3;
  }

  .negative {
    color: $color-red-7;
  }
}
