@import 'src/styles/custom/variables/variables';

.tile-escalation {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &__title {
    font-size: 52px;
    font-weight: 600;
    line-height: 1.5;
  }

  &__title-small {
    font-size: 24px;
    font-weight: 600;

    .tile--horizontal & {
      display: none;
    }
  }

  &__subtitle {
    margin-bottom: 36px;
    font-size: 52px;
    font-weight: 600;
    line-height: 1.2;
  }

  &__subtitle-small {
    font-size: 14px;
    line-height: 115%;

    .tile--horizontal & {
      margin-top: 10px;
    }
  }

  &__legend-text {
    margin-left: 15px;
    font-size: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__table-wrap {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__date-label {
    font-size: 36px;
    font-weight: 600;
  }

  &__date-input {
    height: 80px;
    border: 2px solid $color-grey-2;
    border-radius: 10px;
    color: $color-grey-1;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
  }

  &__date-input-small {
    display: inline-block;
    min-width: 93px;
    max-width: 50ch;
    padding: 0 10px;
    overflow: hidden;
    border-bottom: 1px solid $color-grey-1;
    text-overflow: ellipsis;
  }

  &__table {
    th {
      text-align: center;

      &:nth-child(1) {
        width: 2%;
      }

      &:nth-child(2) {
        width: 5%;
      }

      &:nth-child(3) {
        width: 9%;
      }

      &:nth-child(4) {
        width: 7%;
      }

      &:nth-child(5) {
        width: auto;
      }

      &:nth-child(6) {
        width: 8%;
      }

      &:nth-child(7) {
        width: 10%;
      }

      &:nth-child(8) {
        width: 4%;
      }

      &:nth-child(9) {
        width: 5%;
      }

      &:nth-child(10) {
        width: 8%;
      }
    }

    td {
      font-weight: normal;

      &:nth-child(2) {
        text-align: center;
      }

      &:nth-child(3) {
        text-align: center;
      }

      &:nth-child(6) {
        text-align: center;
      }
    }

    &--empty {
      height: 100%;

      table {
        height: 100%;
      }
    }
  }

  .table--small {
    .table__indicator {
      width: 10px;
      height: 10px;
    }

    th {
      width: auto;
      font-size: 14px;

      &:nth-child(5) {
        text-align: left;
      }

      &:last-child {
        border-right: none;
      }
    }

    td {
      padding: 1px;
      font-size: 16px;
    }

    tr {
      height: 30px;
    }
  }
}
