@import 'src/styles/custom/variables/variables';

.input {
  &__option {
    display: block;
  }

  &--inline &__option {
    display: inline-flex;
    align-items: center;
    margin-right: 40px;
    margin-bottom: 0;
  }

  &__radio {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}
