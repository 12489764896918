@import 'src/styles/custom/variables/variables';

.select {
  &__control {
    min-height: $size-input-height;
    border: 2px solid $color-grey-2;
    border-radius: 0;
    background-color: $color-white;
    color: $color-grey-1;

    &--is-focused {
      border-color: $color-grey-1;
      background-color: $color-white;
    }
  }

  &__value-container {
    padding: 0 12px;

    .autocomplete__control--is-focused &,
    &--has-value {
      border-color: $color-grey-2;
      background-color: $color-white;
    }

    .input--error & {
      border-color: $color-red;
      background-color: $color-white;
    }
  }

  &__indicator {
    padding: 0 12px;

    svg {
      width: 35px;
      height: 35px;
    }
  }

  .input--select-table & {
    .select__control {
      border-radius: 10px;
    }
  }

  &--date {
    width: 169px;
    font-weight: 600;

    @include no-font-scaling-parent {
      width: 80px;
    }

    .select__single-value {
      color: $color-grey-1;
    }

    .select__control {
      min-height: 80px;
      border: 2px solid $color-grey-2;
      border-radius: 10px;

      @include no-font-scaling-parent {
        height: 35px;
        font-size: 16px;
      }

      &--menu-is-open {
        border: 2px solid #3e3e3e;
      }
    }

    .select__option {
      color: $color-grey-1;
      font-weight: normal;

      @include no-font-scaling-parent {
        padding: 4px 12px;
        font-size: 16px;
      }

      &:active,
      &:focus {
        background-color: transparent;
      }

      &:hover {
        background-color: $color-grey-4;
      }
    }

    .select__menu {
      margin-top: 3px;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
    }

    .select__option--is-selected {
      background-color: $color-white;
      font-weight: 600;
    }

    .select__option--is-focused {
      background-color: $color-white;
    }

    path {
      fill: $color-grey-1;
    }
  }
}
