.empty {;
  border: none !important;
  background-color: transparent !important;
  font-size: 0 !important;
}

.no-border {
  border: none !important;
}

.no-background {
  background-color: transparent !important;;
}

.vertical-top {
  vertical-align: top !important;;
}

.text-600 {
  font-weight: 600 !important;;
}
