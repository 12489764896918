@font-face {
  font-family: 'Source Sans Pro';
  font-weight: normal;
  src: local('Source Sans Pro'), url('./../../../fonts/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  src: local('Source Sans Pro'), url('./../../../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  src: local('Source Sans Pro'), url('./../../../fonts/SourceSansPro-Bold.ttf') format('truetype');
}
