@import 'src/styles/custom/variables/variables';

.table-input {
  position: relative;

  &--editing &__input {
    display: block;
  }

  &--editing &__text,
  &--editing &__overlay {
    display: none;
  }


  &__input {
    display: none;
    position: relative;
    z-index: 0;
    width: calc(100% - 40px);
    max-width: 100%;
    margin: 0 20px;
    padding: 0 20px;
    border: 2px solid $color-grey-1;
    border-radius: 10px;
    outline: none;
    color: $color-grey-1;
  }

  &__text {
    display: inline-block;
    width: 100%;
    padding: 0 20px;
  }

  &__overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &--small {
    .table-input__input {
      width: calc(100% - 20px);
      margin: 0 10px;
    }

    .table-input__text {
      padding: 0 5px;
    }
  }

  &--text {
    user-select: none;

    .table-input__input {
      user-select: none;
    }

    .table-input__overlay {
      cursor: default;
      user-select: none;
    }
  }
}
