@import 'src/styles/custom/variables/variables';

.calendar {
  &--form-input {
    max-width: 263px;
    margin-bottom: 35px;

    .calendar__input {
      max-width: 263px;
      padding: 6px 20px;
      border: 2px solid $color-grey-2;
    }
  }

  &--small {
    .calendar__input {
      width: 90px;
    }
  }

  &--centered {
    display: flex;
    justify-content: center;

    .calendar__input {
      max-width: 200px;
      text-align: center;

      @include no-font-scaling-parent {
        max-width: 110px;
      }
    }

    .react-datepicker-wrapper {
      width: auto;
    }
  }

  &__input {
    max-width: 200px;
    border: none;
    background-color: transparent;
    color: $color-grey-1;

    &:focus {
      background-color: $color-white;
    }
  }

  &__label {
    display: block;
    margin-bottom: 10px;
    color: $color-grey-1;
    font-size: 28px;
    font-weight: 600;
    line-height: 1;
  }
}

// override react datepicker
.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker-wrapper {
  width: 100%;
}
