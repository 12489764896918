@import 'src/styles/custom/variables/variables';

.section-header {
  margin-bottom: 45px;
  box-shadow: $box-shadow-default;

  &__text {
    padding: 13px 0;
  }

  &__right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
