@import 'src/styles/custom/variables/variables';

.autocomplete {
  &__control {
    border: none;
    border-radius: 0;
    background-color: $color-grey-4;

    &--is-focused {
      border-color: $color-grey-1;
      background-color: $color-white;
    }
  }

  &__value-container {
    padding: 12px;
    border: 1px solid $color-grey-2;

    .autocomplete__control--is-focused &,
    &--has-value {
      border-color: $color-grey-1;
      background-color: $color-white;
    }

    .input--error & {
      border-color: $color-red;
      background-color: $color-white;
    }
  }

  &__search-highlight {
    font-weight: bold;
  }

  &__multi-value {
    transition: box-shadow 0.15s linear;
    border-radius: 10px;
    background-color: $color-grey-3;

    &:hover {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    }
  }

  // stylelint-disable-next-line
  &__multi-value__remove {
    color: $color-grey-1;

    // stylelint-disable-next-line
    &:hover {
      background-color: transparent;
      color: $color-black;
    }

    svg {
      width: 35px;
      height: 35px;
    }
  }
}
