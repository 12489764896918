@import 'src/styles/custom/variables/variables';

.tile-order-amount {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &__title {
    margin-bottom: 40px;
    font-size: 52px;
    font-weight: 600;
    line-height: 1.2;
  }

  &__sub-title {
    font-size: 36px;
  }

  &--small {
    .tile-order-amount__title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 24px;
    }

    .tile-order-amount__sub-title {
      margin-bottom: 10px;
      font-size: 14px;
    }

    .tile-completion-date__table {
      td:last-child {
        width: 30%;
      }
    }

    td:last-child .table-input__text {
      text-align: center;
    }
  }

  &__table {
    th {
      width: auto;
      vertical-align: top;

      &:first-child {
        width: 197px;
      }

      &:nth-child(3) {
        width: 800px;
      }

      &:nth-child(4) {
        width: 140px;
      }
    }

    &.table--small {
      th {
        height: 10px;
        padding: 5px;
      }

      th:first-child {
        width: 54px;
      }

      th:nth-child(3) {
        width: 30%;
      }

      .table-input__text {
        padding: 0 10px;
      }
    }
  }

  &__table-wrap {
    flex-grow: 1;
    overflow-y: auto;
  }

  .input__input {
    width: 940px;
  }
}
