@import 'src/styles/custom/variables/variables';

.status-red {
  &__info-text {
    margin-left: 15px;
    font-size: 36px;
    font-weight: 600;
  }

  &--sm &__info-text {
    font-size: 10px;
    line-height: 12px;
  }
}
