@import 'src/styles/custom/variables/variables';

.tile-obstacles {
  &__info-text {
    margin-left: 15px;
    font-size: 36px;

    &-title {
      font-weight: 600;
    }
  }

  &__status {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: $color-red;
  }

  &--small {
    .tile-obstacles__info-text-title {
      font-size: 10px;
    }

    .tile-obstacles__info-text {
      font-size: 10px;
      line-height: 12px;
    }

    .tile-obstacles__status {
      width: 10px;
      height: 10px;
    }
  }

  &.tile-table--small {
    th {
      padding: 1px;
    }

    td:nth-child(12) {
      min-width: 50px;
    }

    .table-input__text {
      padding: 0 1px;
      line-height: 1.1;
      word-break: break-word;
    }

    .calendar__input {
      max-width: 60px;
    }
  }
}
