@import 'src/styles/custom/variables/variables';

.form-status {
  display: flex;
  align-items: center;


  &__text {
    font-size: 36px;
    font-weight: 600;
  }

  &__indicator {
    width: 80px;
    height: 80px;
    padding: 2px;
    border: 2px solid $color-grey-2;
    border-radius: 50%;
    background-clip: content-box;

    &--green {
      background-color: $color-green-1;
    }

    &--red {
      background-color: $color-red;
    }
  }

  &--small &__text {
    font-size: 10px;
  }

  &--small &__indicator {
    width: 24px;
    height: 24px;
    padding: 1px;
    border: 1px solid $color-grey-2;
  }
}

