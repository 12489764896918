@import 'src/styles/custom/variables/variables';

.status-text-icon {
  display: inline-flex;
  align-items: center;
  margin-right: 20px;

  @include no-font-scaling-parent {
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 0;
  }

  &__icon {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 20px;
    border-radius: 50%;
    background-color: $color-black;

    @include no-font-scaling-parent {
      width: 12px;
      height: 12px;
      margin-right: 10px;
    }
  }

  &__text {
    font-size: 24px;

    @include no-font-scaling-parent {
      font-size: 16px;
    }
  }

  &--sm {
    margin-right: 5px;

    .status-text-icon__icon {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }

    .status-text-icon__text {
      margin-left: 0;
      font-size: 10px;
      line-height: 12px;
    }
  }

  &--square {
    .status-text-icon__icon {
      border-radius: 5px;
    }
  }

  &--red {
    .status-text-icon__icon {
      background-color: $color-red;
    }
  }

  &--green {
    .status-text-icon__icon {
      background-color: $color-green-3;
    }
  }

  &--orange {
    .status-text-icon__icon {
      background-color: $color-orange-1;
    }
  }

  &--white {
    .status-text-icon__icon {
      border: 1px solid $color-grey-2;
      background-color: $color-white;
    }
  }
}

