@import 'src/styles/custom/variables/variables';

.tile-goal-setting {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  font-size: 14px;

  .table-input__input {
    height: 100%;
  }

  .table-input__text {
    height: 100%;
  }

  .table-input {
    height: 100%;
  }

  &__title {
    margin-bottom: 25px;
    font-size: 68px;
    font-weight: bold;
  }

  &__sub-title {
    margin-bottom: 50px;
    font-size: 36px;
  }

  &__table-top {
    font-size: 14px;

    .table-input__input {
      font-weight: bold;
    }

    .table-input__text {
      display: flex;
      align-items: center;
      font-weight: bold;
    }

    tr {
      height: auto;
    }

    th {
      width: auto;
      height: 174px;
      font-weight: 600;
      text-align: center;

      &:nth-of-type(2) {
        width: 120px;
      }
    }

    td {
      height: 78px;
    }
  }

  &__status-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__table-top-wrap {
    width: 50%;
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__top-status {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 0 3px;
    border-radius: 25px;
    background-color: $color-red-7;

    &--small {
      width: 8px;
      height: 8px;
    }
  }

  &__top-status-wrap {
    padding-right: 5px;
    font-size: 36px;
    text-align: right;
  }

  &__table-top-heading {
    font-size: 36px;
  }

  &__table-top-subheading {
    font-size: 24px;
  }

  &__status {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 40px;
    border: 1px solid $color-grey-2;
    border-radius: 50%;
    color: transparent;
    font-size: 0;

    &--green {
      background-color: $color-green-1;
    }

    &--red {
      background-color: $color-red;
    }

    &--small {
      margin: 23px;
    }
  }

  &__table-bottom {
    margin-top: 80px;
    font-size: 14px;

    tr {
      height: auto;
    }

    th {
      width: auto;
      font-weight: 600;
      text-align: center;

      &:nth-of-type(2) {
        width: 15%;
      }

      &:nth-of-type(3) {
        width: 15%;
      }

      &:nth-of-type(4) {
        width: 3%;
      }
    }

    td {
      width: auto;
      height: 30px;
    }

    .calendar {
      min-width: 100%;
    }
  }

  &--small {
    .tile-goal-setting__title {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 400;

      &--gfboard {
        font-size: 20px;
      }

      &--brlboard {
        font-size: 24px;
      }
    }

    .tile-goal-setting__sub-title {
      margin-bottom: 0;
      font-size: 14px;
    }

    .tile-goal-setting__table-top {
      th,
      td {
        padding: 2px;
        font-size: 14px;
      }

      td {
        height: 37px;
        line-height: 1;
      }

      th {
        width: auto;
        height: 25px;
      }

      th:nth-child(2) {
        width: 60px;
      }
    }

    .tile-goal-setting__table-top--left {
      td {
        height: 47px;
      }
    }

    .tile-goal-setting__table-top-wrap {
      margin-right: 9px;

      &:last-child {
        margin-right: 0;
      }
    }

    .tile-goal-setting__status {
      width: 15px;
      height: 15px;
      margin: 0;
      padding: 8px;
    }

    .tile-goal-setting__table-bottom {
      margin-top: 0;

      th,
      td {
        padding: 2px;
        font-size: 14px;
      }

      th {
        width: auto;
        height: 25px;
      }

      th:nth-child(2) {
        width: 140px;
      }

      th:nth-child(3) {
        width: 140px;
      }
    }

    .tile-goal-setting__top-status-wrap {
      padding-right: 5px;
      font-size: 14px;
      text-align: right;
    }

    .tile-goal-setting__table-top-heading {
      font-size: 12px;
    }

    .tile-goal-setting__table-top-subheading {
      font-size: 8px;
    }
  }
}
