@import 'src/styles/custom/variables/variables';

.tile-inspection-plans-qa {
  .tile-table__table {
    th:nth-child(7) {
      padding: 0;
    }
  }

  &.tile-table--small {
    tr:first-child th {
      vertical-align: top;
    }

    .tile-inspection-plans-qa__quality-check-header {
      > div {
        padding: 3px;
      }
    }
  }

  &__quality-check-header {
    font-weight: 600;
    text-align: center;

    > div {
      padding: 10px;
    }

    > div:first-child {
      border-bottom: 1px solid $color-grey-2;
    }
  }

  &__info {
    .status-text-icon__text {
      font-size: 24px;
    }

    &--small {
      line-height: 0.4;

      .status-text-icon__text {
        font-size: 10px;
      }
    }
  }
}
