@import 'src/styles/custom/variables/variables';

.toggle-switch {
  display: inline-block;
  position: relative;
  width: 85px;
  margin-right: 10px;
  text-align: left;
  vertical-align: middle;

  @include no-font-scaling-parent {
    width: 34px;

    .toggle-switch__switch {
      top: -4px;
      right: 15px;
      width: 20px;
      height: 20px;
    }
  }

  &__checkbox {
    display: none;
  }

  &__label {
    display: block;
    margin: 0;
    overflow: hidden;
    border: 0;
    border-radius: 25px;
    cursor: pointer;
  }

  &__inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &::before,
    &::after {
      display: block;
      box-sizing: border-box;
      width: 50%;
      height: 35px;
      padding: 0;
      float: left;
      color: $color-white;
      font-size: 14px;
      font-weight: bold;
      line-height: 34px;
    }

    &::before {
      content: attr(data-yes);
      padding-left: 10px;
      background-color: $color-green-4;
      color: $color-white;
      text-transform: uppercase;
    }
  }

  &__inner::after {
    content: attr(data-no);
    padding-right: 10px;
    background-color: $color-grey-2;
    color: $color-white;
    text-align: right;
    text-transform: uppercase;
  }

  &__switch {
    display: block;
    position: absolute;
    top: -6px;
    right: 40px;
    bottom: 0;
    width: 50px;
    height: 50px;
    transition: all 0.3s ease-in 0s;
    border: 1px solid $color-grey-2;
    border-radius: 30px;
    background: $color-white;
  }

  &__checkbox:checked + &__label {
    .toggle-switch__inner {
      margin-left: 0;
    }

    .toggle-switch__switch {
      right: -10px;
      border: 0;
      background: $color-green-3;
    }
  }
}
