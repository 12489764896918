@import 'src/styles/custom/variables/variables';

.info-page {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding-bottom: 200px;
  background-color: $color-white;
  text-align: center;

  &__title {
    margin-bottom: 40px;
    font-size: 120px;
    font-weight: 700;
    line-height: 1.1;
  }

  &__subtitle {
    align-self: center;
    width: 880px;
    margin-bottom: 60px;
    font-size: 36px;
    line-height: 1.2;
  }
}
