@import 'src/styles/custom/variables/variables';

.tile-supply-schedule {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__date-label {
    margin-right: 20px;
    font-size: 36px;
    font-weight: 600;

    @include no-font-scaling-parent {
      margin-right: 10px;
      font-size: 16px;
    }
  }

  &__date-input {
    width: 1485px;
    height: 80px;
    padding: 23px;
    border: 2px solid $color-grey-2;
    border-radius: 10px;
    color: $color-grey-1;
    font-size: 36px;
    font-weight: 600;
    text-align: left;

    @include no-font-scaling-parent {
      width: 444px;
      height: 30px;
      font-size: 16px;
    }
  }

  &__date-input-small {
    display: inline-block;
    min-width: 93px;
    max-width: 50ch;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__table-tv-screen {
    th {
      width: 78.4px;
    }
  }

  &__table-desktop-screen {
    th {
      width: 80px;
    }
  }

  &__info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__info-text {
    margin-top: 23px;
    font-size: 36px;
    line-height: 1;

    @include no-font-scaling-parent {
      margin-top: 13px;
      font-size: 16px;
    }

    &-title {
      font-weight: 600;
    }
  }

  &__info-text-date {
    margin: 44px 0 40px;
    font-size: 52px;
    font-weight: 600;
    line-height: 1;

    @include no-font-scaling-parent {
      margin-top: 15px 0 16px 0;
      font-size: 24px;
      line-height: 1.3;
    }
  }

  &__date {
    font-size: 52px;
    font-weight: 600;
    line-height: 1.35;

    @include no-font-scaling-parent {
      font-size: 19.5px;
    }
  }

  &__day-name {
    color: $color-grey-6;
    font-weight: 600;
    line-height: 1.25;

    @include no-font-scaling-parent {
      margin-top: 2px;
      font-size: 13.5px;
    }
  }

  .min-w-auto {
    min-width: auto !important;
  }

  &__table-column {
    display: flex;
    align-items: center;
    height: auto;
  }

  &__table-status-wrap {
    display: flex;
    flex-direction: column;
    width: 25px;
    min-height: 120px;
  }

  &__table-status {
    width: 100%;
    height: 60px;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: $color-grey-10;
    font-size: 0;

    @include no-font-scaling-parent {
      height: 24px;
    }

    &--green {
      background-color: $color-green-3;
    }

    &--red {
      background-color: $color-red-7;
    }

    &--gray {
      background-color: $color-grey-1;
    }
  }

  &__table-area {
    flex-grow: 1;

    .textarea {
      margin-bottom: 0;
      padding: 19px;

      @include no-font-scaling-parent {
        padding: 5px;
      }
    }

    .textarea__textarea {
      height: 80px;
      padding: 0;
      border: none;

      @include no-font-scaling-parent {
        height: 38px;
        font-size: 16px;
      }
    }
  }

  .text-grey {
    color: $color-grey-6;

    @include no-font-scaling-parent {
      font-size: 13.5px;
    }
  }

  .nav__arrow--right {
    margin-left: 35px;
  }

  &--small {
    .tile-supply-schedule__title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
    }

    .tile-supply-schedule__info-text-title {
      font-size: 10px;
    }

    .tile-supply-schedule__info-text {
      margin-top: 0;
      font-size: 14px;
      line-height: 12px;
    }

    .tile-supply-schedule__info-wrap {
      margin: 0;
    }

    .tile-supply-schedule__info-text-date {
      margin: 10px 0 5px;
      font-size: 16px;
      line-height: 16px;
    }

    .tile-supply-schedule__date {
      font-size: 16px;
    }

    .tile-supply-schedule__table-status {
      height: 9px;
    }

    .tile-supply-schedule__table-status-wrap {
      width: 4px;
      min-height: 18px;
    }

    .textarea {
      padding: 0;
    }

    .textarea__textarea {
      height: 20px;
      padding-left: 3px;
      overflow: hidden;
      font-size: 16px;
    }
  }
}
