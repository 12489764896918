@import 'src/styles/custom/variables/variables';

.input {
  margin-bottom: 35px;

  &--error {
    margin-bottom: 5px;

    .input__input {
      border-color: $color-red-7;
    }
  }

  &--grey {
    .input__input {
      background-color: $color-grey-4;
    }
  }

  &__label {
    display: block;
    margin-bottom: 10px;
    color: $color-grey-1;
    font-size: 28px;
    font-weight: 600;
    line-height: 1;

    &--lg {
      margin-bottom: 22px;
      font-size: 36px;
    }
  }

  &__input {
    width: 100%;
    height: $size-input-height;
    padding: 12px 26px;
    border: 2px solid $color-grey-2;
    color: $color-grey-1;
    font-size: 36px;
    font-weight: normal;
    line-height: 1;
  }

  &__error {
    margin-bottom: 11px;
    border-color: $color-red-7;
    font-size: 24px;
  }
}
