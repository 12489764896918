@import 'src/styles/custom/variables/variables';

.tile-table-form-measures {
  height: 100%;
  overflow-y: auto;

  &__sub-title {
    font-size: 36px;

    &.bold {
      font-weight: bold;
    }
  }

  &__status-text {
    margin-bottom: 25px;
    text-align: right;
  }

  &__status-label {
    font-weight: 600;
  }

  &--small &__title {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: 600;
  }

  &--small &__sub-title {
    margin-bottom: 10px;
    font-size: 14px;
  }

  &--small {
    th {
      height: 50px;
      padding: 10px 10px 5px;
    }

    .table-input__text {
      padding: 0 10px;
    }
  }

  .table--small &__table-label {
    color: $color-grey-2;
  }
}
