@import 'src/styles/custom/variables/variables';

.tile-completion-date-desktop-screen.no-font-scaling {
  height: 100%;
  overflow-y: auto;

  .table {
    flex-grow: 1;
    border: none;
  }

  table {
    height: 100%;
  }

  tr {
    height: auto;
    padding: 0 13px;
    border-top: 1px solid $color-grey-3;

    &:first-child {
      border-top: none;

      td:first-child {
        font-weight: 600;
      }

      td:nth-child(2) {
        background-color: $color-grey-9;
      }
    }
  }

  th,
  td {
    padding-right: 14px;
    padding-left: 15px;
    border: none;
  }

  th {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
  }

  td {
    min-height: 24px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;

    &:first-child {
      padding-right: 2px;
    }

    &:last-child {
      width: 90px;
      padding: 0 10px;
    }
  }
}
