@import 'src/styles/custom/variables/variables';

.breadcrumb-navigation {
  flex-direction: column;
  overflow: hidden;

  // override bootstrap
  .breadcrumb {
    display: inline-flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    background: $color-red-3;
  }

  .breadcrumb-item {
    display: inline-flex;
    align-items: center;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .breadcrumb-navigation__arrow {
    display: flex;
    position: relative;
    padding: 0 20px;

    &--opened {
      .breadcrumb-navigation__arrow-inner {
        transform: rotate(90deg);
      }
    }

    &--disabled {
      .breadcrumb-navigation__arrow-inner {
        outline: none;
        background-color: transparent;
        cursor: default;
      }
    }
  }

  .breadcrumb-navigation__arrow-inner {
    content: url('../../../../img/icon/breadcrumb-divider-icon.svg');
    display: inline-block;
    width: 45px;
    height: 45px;
    padding: 11px;
    border-radius: 3px;
    background-color: $color-red-9;
    cursor: pointer;
  }

  .breadcrumb-navigation__item {
    overflow: hidden;
    color: $color-white;
    font-size: 36px;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--current {
      font-weight: 600;
    }
  }
}

.breadcrumb-navigation__children-wrap {
  // bootstrap override

  &.tooltip.show {
    opacity: 1;
  }

  .arrow {
    visibility: hidden;
  }

  .tooltip-inner {
    min-width: 600px;
    max-width: 530px;
    background-color: transparent;
  }

  .breadcrumb-navigation__children {
    max-height: 93vh;
    margin-top: 30px;
    padding: 0;
    overflow-y: auto;
    border-radius: 5px;
    background-color: $color-red-4;
    box-shadow: 0 4px 8px rgba(82, 16, 17, 0.35);
    font-size: 30px;
    list-style: none;
    text-align: left;
  }

  .breadcrumb-navigation__child {
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;

      .breadcrumb-navigation__child-item-wrap {
        border-radius: 0 0 5px 5px;
      }
    }
  }

  .breadcrumb-navigation__child-link {
    display: flex;
    align-items: center;
    color: $color-white;
    word-break: break-word;
    // stylelint-disable
    &--active > .icon {
      path,
      rect {
        fill: $color-white;
      }
    }
    // stylelint-enable
  }

  .breadcrumb-navigation__child-icon {
    margin-right: 30px;

    &.icon {
      flex-shrink: 0;
      width: 30px;
      height: 30px;
    }

    svg {
      width: 100px;
      height: 100px;
    }

    path,
    rect {
      fill: $color-red-11;
    }
  }

  .breadcrumb-navigation__child-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;

    &--has-no-children {
      padding: 16px 15px 16px 65px;
    }
  }

  .breadcrumb-navigation__child-info {
    background-color: $color-red-9;
  }

  .breadcrumb-navigation__child-section {
    padding-bottom: 30px;
    border-bottom: 1px solid rgba($color-red-12, 0.6);

    &:last-child {
      border-bottom: none;
    }
  }

  .breadcrumb-navigation__child-section-title {
    padding: 30px;
    color: $color-red-11;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .breadcrumb-navigation__child-item-wrap {
    padding: 0 20px 0 30px;
    list-style-type: none;
    background-color: $color-red-9;
  }

  .breadcrumb-navigation__child-item {
    margin-bottom: 30px;
    padding: 0;
    line-height: 1.2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .breadcrumb-navigation__child-item-link {
    display: flex;
    flex-direction: row;
    color: $color-white;
  }

  .breadcrumb-navigation__child-arrow {
    padding: 15px;
    transition: transform 0.2s ease-in-out;
    border: none;
    outline: none;
    background-color: transparent;

    &--active {
      transform: rotate(180deg);
    }

    .icon {
      width: 20px;
      height: 12px;
    }
  }
}
