@import 'src/styles/custom/variables/variables';

.tile-completion-date {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &__title {
    margin-bottom: 40px;
    font-size: 52px;
    font-weight: 600;
    line-height: 1.2;
  }

  &__sub-title {
    font-size: 36px;
  }

  &--small {
    .tile-completion-date__title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 24px;
    }

    .tile-completion-date__sub-title {
      margin-bottom: 10px;
      font-size: 14px;
    }

    .tile-completion-date__table {
      td:last-child {
        width: 30%;
      }
    }

  }

  &__table {
    th {
      width: auto;

      &:first-child {
        width: 197px;
      }

      &:nth-child(3) {
        width: 800px;
      }

      &:nth-child(4) {
        width: 140px;
      }
    }

    &.table--small {
      th {
        height: 20px;
        padding: 5px;
      }

      th:first-child {
        width: 54px;
      }

      th:nth-child(3) {
        width: auto;
      }

      th:nth-child(4) {
        width: 30%;
      }

      .table-input__text {
        padding: 0 10px;
      }

      .calendar__input {
        padding: 0 10px;
        text-align: center;
      }
    }

    .calendar__input {
      width: 100%;
      max-width: 100%;
      padding-left: 30px;
      text-align: left;
    }
  }

  &__table-wrap {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__table-wrap-top {
    border: 1px solid $color-grey-2;
    border-bottom: none;
  }

  .input__input {
    width: 940px;
  }

  .calendar--form-input {
    width: 940px;
    max-width: 100%;
    margin-bottom: 0;
  }

  .calendar--form-input .calendar__input {
    width: 940px;
    max-width: 100%;
    background-color: $color-grey-4;
    text-align: left;
  }
}
