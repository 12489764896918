.tile-power-bi-preview {
  height: 100%;

  &__report {
    width: 85%;
    height: 100%;
    margin: auto;

    iframe {
      border: none;
    }
  }
}
