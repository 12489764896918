@import 'src/styles/custom/variables/variables';
@import 'src/styles/vendor/bootstrap/bootstrap-variables';

.board-header {
  display: flex;
  flex-direction: column;

  &__logo {
    width: 140px;
    height: 140px;
    max-height: 100%;
  }

  &__back {
    display: flex;
    align-items: center;
    max-height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    color: $color-white;
  }

  &__icon {
    padding: 24px;
    border-radius: 5px;
    background-color: $color-grey-1;

    &:hover {
      background-color: $color-black;
    }
  }

  &__subheading {
    position: absolute;
    top: 50%;
    right: 230px;
    transform: translateY(-50%);
    color: $color-white;
  }

  &__name {
    margin-right: 20px;
    color: $color-white;
  }

  &__logout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 85px;
    margin-right: 20px;
    padding: 15px;
    border: none;
    border-radius: 8px;
    outline: none;
    background-color: $color-red-5;

    &:hover {
      background-color: $color-red-6;
    }

    &:focus {
      outline: none;
    }
  }

  &__wrap {
    min-height: 137px;
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 35%;
  }

  &__center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 35%;
  }
}
