@import 'src/styles/custom/variables/variables';

.table {
  width: 100%;
  border: 2px solid $color-grey-2;

  @include no-font-scaling {
    border: 1px solid $color-grey-2;

    th,
    td {
      border: 1px solid $color-grey-2;
      border-top: none;
      border-bottom: 1px solid $color-grey-3;
      font-size: 16px;
    }

    td,
    th {
      &:last-child {
        border-right: none;
      }
    }

    tr {
      height: 40px;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }

    // stylelint-disable-next-line
    tfoot tr:last-child td {
      border-bottom: 1px solid $color-grey-2;
    }

    .table__title {
      font-size: 16px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    height: 80px;
  }

  th {
    width: 70px;
    height: 70px;
    background: $color-grey-4;
    color: $color-black-2;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
  }

  .th,
  .tile-attendance-list-helpers-current-week {
    background: $color-grey-2;
  }

  td,
  th {
    padding: 6px;
    border: 2px solid $color-grey-2;
    border-top: none;
    border-bottom: 1px solid $color-grey-2;
    border-left: none;
    color: $color-grey-1;
    font-size: 36px;
    text-align: left;
    vertical-align: middle;

    &:last-child {
      border-right: none;
    }

    &:first-child {
      border-left: none;
    }
  }

  // stylelint-disable-next-line
  tr:last-child td {
    border-bottom: none;
  }

  th {
    padding: 16px;
    vertical-align: top;
  }

  .active {
    padding: 0;
  }

  .active-focus:focus-within {
    padding: 0;
  }

  .shrink {
    width: 1px;
  }

  .grow {
    width: 100%;
  }

  &__title {
    margin: 0;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.2;
  }

  &__subtitle {
    color: $color-black-2;
    font-size: 24px;
    font-weight: normal;
    text-align: center;

    @include no-font-scaling-parent {
      font-size: 12px;
    }
  }

  &__input {
    width: 100%;
    padding: 6px;
    border: 1px solid $color-grey-2;
    color: $color-grey-1;
    font-size: 36px;
    text-align: left;
  }

  &__row {
    &--highlighted {
      background-color: $color-blue-2;
    }
  }

  &__text {
    &--small {
      font-size: 24px;
      font-weight: 400;
    }
  }

  &__indicator {
    display: inline-block;
    width: 65px;
    height: 65px;
    border: 1px solid $color-grey-2;
    border-radius: 10%;
    outline: none;
    vertical-align: middle;
    cursor: pointer;


    &--black {
      background-color: $color-black;
    }

    &--red {
      background-color: $color-red;
    }

    &--green {
      background-color: $color-green-1;
    }

    &--orange {
      background-color: $color-orange-1;
    }

    &--yellow {
      background-color: $color-orange-1;
    }

    &--grey {
      background-color: $color-grey-2;
    }

    &--checked {
      background-color: $color-black;
    }

    &--lg {
      width: 75px;
      height: 75px;
    }
  }

  &__text-overflow-container {
    position: relative;
    max-height: 1.2em;
    overflow: hidden;
    color: transparent;
    line-height: 1.2;

    &::after {
      content: attr(title);
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      overflow: hidden;
      transform: translateY(-50%);
      color: $color-grey-1;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--small {
    th {
      width: 16px;
      height: 22px;
      padding: 0 2px;
      font-size: 12px;
    }

    td {
      padding: 1px;
      font-size: 12px;
    }

    tr {
      height: 18px;
    }

    .table__title {
      font-size: 12px;
    }

    .table__subtitle {
      font-size: 9px;
    }

    .table__indicator {
      width: 10px;
      height: 10px;
      border: none;

      &--white {
        background-color: $color-white;
      }

      &--undefined {
        border: 1px solid $color-grey-2;
      }
    }

    .table__indicator.no-font-scaling {
      width: 12px;
      height: 12px;
      border-radius: 2px;
    }

    .table__indicator--round.no-font-scaling {
      border-radius: 50%;
    }

    .table__indicator--white.no-font-scaling {
      border: 1px solid $color-grey-2;
    }

    .table__text--small {
      font-size: 10px;
    }

    .calendar__input {
      width: 100px;
    }
  }

  &--dnd {
    tbody {
      background-color: $color-grey-4;
    }

    tr {
      background-color: $color-white;
    }

    .table__row-drag {
      display: flex;
      border: 2px solid $color-blue;

      td {
        border: 1px solid transparent;
      }
    }
  }

  &--small {
    position: relative;

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  @for $i from 5 through 45 {
    .w-#{$i} {
      width: round(percentage($i/100)) !important;
    }
  }


  .last-row {
    background-color: $color-grey-4 !important;

    .title {
      font-weight: 600 !important;
    }

    .sum {
      border: 0.5px solid $color-grey-2 !important;
      background-color: $color-white !important;
    }
  }

  .table-sum-title {
    padding-left: 30px !important;
    background-color: $color-grey-4 !important;
    font-weight: 600 !important;
  }

  .table-sum-number {
    padding-left: 25px !important;
    border-right: 1px solid $color-grey-2 !important;
    color: $color-grey-6 !important;
  }

  .table-item-disabled {
    color: $color-grey-2;
    pointer-events: none;

    .calendar__input {
      color: $color-grey-2;
    }

    // stylelint-disable-next-line
    .icon path {
      fill: $color-grey-3;
    }

    .textarea__textarea {
      color: $color-grey-2;
    }

    .table__indicator {
      border-color: $color-grey-3;
    }

    .table__indicator--checked {
      background-color: $color-grey-3;
    }
  }
}
