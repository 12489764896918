@import 'src/styles/custom/variables/variables';

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;
  border: none;
  background-color: transparent;

  &--small {
    width: 20px;
    height: 20px;
  }

  &--medium {
    width: 36px;
    height: 36px;

    @include no-font-scaling-parent {
      width: 18px;
      height: 18px;
    }
  }

  &--full-size {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  svg {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  &--delete {
    &:hover {
      path {
        fill: $color-grey-1;
      }
    }
  }

  // stylelint-disable-next-line
  &--levelUp:hover {
    path {
      fill: $color-grey-1;
    }
  }

  &--disabled {
    opacity: 0.5;
  }

  &--inline {
    display: inline-flex;
  }

  &--table-plus {
    width: 33px;
    height: 33px;

    @include no-font-scaling-parent {
      width: 13px;
      height: 13px;
    }

    svg {
      height: 100%;
    }

    path {
      fill: $color-grey-2;
    }

    &:hover {
      path {
        fill: $color-grey-1;
      }
    }
  }

  &--deescalate {
    height: 38px;
    margin: 0 20px;

    &:hover {
      path {
        fill: $color-grey-1;
      }
    }
  }

  // stylelint-disable-next-line
  &--arrowUp, &--arrowDown {
    width: 50px;
    height: 50px;

    @include no-font-scaling-parent {
      width: 24px !important;
      height: 24px !important;
    }

    // stylelint-disable-next-line
    &:hover {
      path {
        fill: $color-grey-1;
      }
    }
  }
}
