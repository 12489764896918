@import 'src/styles/custom/variables/variables';

.project-status {
  margin-bottom: 6px;

  &__title {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  &__status-bar {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    width: 100%;
  }

  &__entry {
    height: 10px;

    &--incomplete {
      background-color: $color-red;
    }

    &--complete {
      background-color: $color-green-3;
    }
  }
}
