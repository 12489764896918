@import 'src/styles/custom/variables/variables';

.tile-confirmation {
  &__legend-text {
    margin-left: 15px;
    font-size: 24px;

    &-title {
      font-weight: 600;
    }
  }

  &--small {
    .tile-confirmation__title {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 24px;
    }

    .tile-confirmation__info-text {
      font-size: 10px;
    }

    .tile-confirmation__legend-text {
      margin-left: 5px;
      font-size: 14px;
    }
  }
}
