.tile-pro-plan.no-font-scaling {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  iframe {
    position: absolute;
    left: 50%;
    width: 997px;
    height: 705px;
    transform-origin: 0 0;
    border: none;
    pointer-events: none;
  }
}
