@import 'src/styles/custom/variables/variables';

.tile-process-confirmation {
  height: 100%;
  overflow-y: auto;

  .table {
    border: 2px solid $color-grey-2;

    th {
      width: 10%;
      height: 243px;
      border: none;
      border-right: 1px solid $color-grey-2;
      border-bottom: 1px solid $color-grey-2;

      &:first-child {
        background-color: $color-white;
      }

      &:not(:first-child) {
        background-color: $color-grey-4;
        vertical-align: initial;
      }

      &:nth-child(2) {
        background-color: $color-grey-5;
      }

      &:last-child {
        border-right: none;
      }
    }

    td {
      border: none;
      border-right: 1px solid $color-grey-2;
      border-bottom: 1px solid $color-grey-2;

      &:nth-child(2) {
        background-color: $color-grey-5;
      }

      &:last-child {
        border-right: none;
      }
    }

    .tile-process-confirmation__description-item {
      padding: 8px;
      vertical-align: top;

      &:first-child {
        background-color: $color-grey-4;
        color: $color-grey-1;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        vertical-align: middle;
      }
    }
  }

  &__header-item {
    padding: 45px 15px 35px;
    text-align: center;
  }

  &__header-title {
    min-height: 50px;
    margin-bottom: 20px;
    color: $color-grey-1;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.04em;
    line-height: 100%;
    text-align: center;
  }

  &__header-sub-title {
    font-size: 24px;
    line-height: 1;
    text-align: center;
  }

  &__description-item {
    vertical-align: top;
  }

  &__indicator {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__indicator-box {
    flex-shrink: 0;
    width: 67px;
    height: 67px;
    margin-right: 15px;
    border: 1px solid $color-grey-2;
    border-radius: 10%;
    background-color: $color-white;

    &--red {
      border: 1px solid $color-red;
      background-color: $color-red;
    }

    &--green {
      border: 1px solid $color-green-3;
      background-color: $color-green-1;
    }

    &--white {
      background-color: $color-white;
    }
  }

  &__indicator-text {
    font-size: 34px;
    font-weight: normal;
    line-height: 100%;
    vertical-align: middle;

    &--sm {
      font-size: 24px;
    }
  }

  &__row {
    text-align: center;

    td {
      padding: 0;
      vertical-align: top;

      &:first-child {
        padding: 20px;
        color: $color-grey-7;
        font-size: 36px;
        font-weight: 600;
        letter-spacing: -0.04em;
        line-height: 100%;
        text-align: center;
        vertical-align: middle;
      }
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  &__column-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
  }

  &__status {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    margin-top: 35px;
    margin-bottom: 35px;
    border: 2px solid $color-grey-2;
    border-radius: 50%;
    background-color: $color-white;
    font-size: 0;

    &--red {
      background-color: $color-red;
    }

    &--green {
      background-color: $color-green-1;
    }

    &--undefined {
      background-color: $color-white;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    margin-bottom: 5px;
    color: $color-grey-2;
    font-size: 20px;
    font-weight: 400;
    line-height: 71.5%;
  }

  &__input-control {
    width: 100%;
    border: 1px solid $color-grey-2;
    outline: none;
    background-color: $color-grey-4;
    color: $color-grey-1;
    font-size: 36px;
    line-height: 1;
    text-align: center;

    &:focus {
      background-color: $color-white;
    }

    &::placeholder {
      color: $color-grey-2;
    }
  }

  &__multiline {
    display: flex;
    align-items: center;
    width: 100%;

    .tile-process-confirmation__status {
      display: inline-block;
      flex-shrink: 0;
      width: 25px;
      height: 25px;
      margin: 9px 0 0 10px;
      padding: 15px;
    }
  }

  &--small {
    .table {
      th {
        height: 140px;
        padding: 3px 2px;
        font-size: 12px;
      }

      td {
        font-size: 12px;
      }

      tr {
        height: auto;
      }

      .tile-process-confirmation__description-item {
        padding: 4px 2px;
      }
    }

    .tile-process-confirmation__header-item {
      min-height: 50px;
      padding: 2px 3px;
    }

    .tile-process-confirmation__header-title {
      min-height: 20px;
      margin-bottom: 10px;
      font-size: 12px;
      word-break: break-word;
    }

    .tile-process-confirmation__header-sub-title {
      font-size: 8px;
      word-break: break-word;
    }

    .tile-process-confirmation__indicator-box {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }

    .tile-process-confirmation__indicator-text {
      font-size: 8px;
      line-height: 100%;

      &--sm {
        font-size: 6px;
      }
    }

    .tile-process-confirmation__status {
      width: 15px;
      height: 15px;
      margin-top: 10px;
      margin-bottom: 15px;
    }

    .tile-process-confirmation__label {
      margin-bottom: 2px;
      font-size: 6px;
      line-height: 1;
    }

    .tile-process-confirmation__input-control {
      font-size: 8px;
    }

    .tile-process-confirmation__row {
      td {
        padding: 4px;
      }
    }

    .tile-process-confirmation__column-inner {
      padding: 0;
    }

    .tile-process-confirmation__input {
      margin-bottom: 1px;
    }

    .tile-process-confirmation__input-control {
      height: 12px;
    }

    .tile-process-confirmation__multiline {
      display: flex;
      align-items: center;
      width: 100%;

      .tile-process-confirmation__status {
        display: inline-block;
        flex-shrink: 0;
        width: 5px;
        height: 5px;
        margin: 5px 0 0 3px;
        padding: 3px;
      }
    }
  }
}
