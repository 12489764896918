.tile-pdf.no-font-scaling {
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  object {
    width: 100%;
    height: 100%;
  }

  &.tile-pdf--small {
    overflow: hidden;

    object {
      width: calc(100% + 17px);
      pointer-events: none;
    }
  }
}
